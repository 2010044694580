import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  HStack,
  IconButton,
  Checkbox,
  Button,
  Modal,
  ModalOverlay,
  Image,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  useToast,
  Avatar,
  Tag,
  TagCloseButton,
  TagLabel,
  SimpleGrid,
  Stack,
  Textarea,
  background,
  Divider,
  Menu, MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse'
import { AddIcon,CloseIcon, EditIcon,  } from '@chakra-ui/icons';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import TDashboard from '../Dashboard/Dashboard.tsx';
import { getClassById, getTeachersByIds, getStudentsByIds, createStudents, addStudentsToClass, addTeachersToClass, addProject, getProjectsByIds,createTeachers, addAnnouncementToClass, fetchAnnouncementsForClass, addCommentToAnnouncement, updateClassDetails, deleteTeacherFromClassProjects,deleteTeacherFromClass } from '../../../Services/Teacher/teacherService.tsx';
import ProjectCard from './projectCard.tsx';
import { pusherClient } from '../../../pusherConfig.js';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { deleteStudentFromClass, deleteStudentFromClassProjects } from '../../../Services/Student/studentService.tsx';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';

const ClassView = () => {
  const [classDetails, setClassDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [teacherLoading, setTeacherLoading] = useState(false);
  const [studentLoading, setStudentLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0); 
  const { classId } = useParams();
  const { isOpen: isInviteStudentsOpen, onOpen: onInviteStudentsOpen, onClose: onInviteStudentsClose } = useDisclosure();
  const { isOpen: isInviteTeachersOpen, onOpen: onInviteTeachersOpen, onClose: onInviteTeachersClose } = useDisclosure();
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [invitedStudentEmails, setInvitedStudentEmails] = useState([]);
  const [invitedTeacherEmails, setInvitedTeacherEmails] = useState([]);
  const tId = sessionStorage.getItem("CurrentLoggedInTId").trim();
  const [announcementError, setAnnouncementError] = useState('');
  const token = sessionStorage.getItem("googleAccessToken");
  

  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        setLoading(true);
        const data = await getClassById(classId);
        setClassDetails(data);
        sessionStorage.setItem("currentClassId",classId);
      } catch (error) {
        console.error('Error fetching class details:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClassDetails();
    const at =  sessionStorage.getItem("activeTabInClass");
    if(at){
      setActiveTab(parseInt(at));
    }
  }, [classId]);

  const fetchTeachers = async (teacherIds) => {
    setTeacherLoading(true);
    try {
      const data = await getTeachersByIds(teacherIds);
      setTeachers(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError(error.message);
    } finally {
      setTeacherLoading(false);
    }
  };
  const fetchCurrentUser = async (teacherIds) => {
    setTeacherLoading(true);
    try {
      const data = await getTeachersByIds(teacherIds);
      setCurrentUser(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError(error.message);
    } finally {
      setTeacherLoading(false);
    }
  };

  const fetchStudents = async (studentIds) => {
    setStudentLoading(true);
    try {
      const data = await getStudentsByIds(studentIds);
      setStudents(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError(error.message);
    } finally {
      setStudentLoading(false);
    }
  };
  const [projectLoading, setProjectLoading] = useState(true);
  const fetchProjects = async (projectIds) => {
    setProjectLoading(true);
    try {
      const data = await getProjectsByIds(projectIds);
      setProjects(data.filter((proj)=>proj.teacherIds.includes(tId)));
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setError(error.message);
    } finally {
      setProjectLoading(false);
    }
  };

  const handleDelete = async (teacherId) => {
    if (teacherId === currentLoggedInTId) {
      toast({
        title: "Error deleting teacher.",
        description: "You cannot delete the currently logged-in user.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setTeacherLoading(true);
    try {
      const response1 = await deleteTeacherFromClassProjects(classDetails.projects, teacherId)
      const response2 =  await deleteTeacherFromClass(classDetails._id, teacherId, token);
      setClassDetails(response2.updatedClass);
      fetchTeachers(response2.updatedClass.teachers);
      toast({
        title: "Teacher removed.",
        description: "The teacher has been removed successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setTeacherLoading(false);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error deleting teacher.",
        description: "There was an error deleting the teacher.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleDeleteStudent = async (studentId) => {
    setStudentLoading(true);

    try {
      const response1 = await deleteStudentFromClassProjects(classDetails.projects, studentId)
      const response2 =  await deleteStudentFromClass(classDetails._id, studentId, token);
      setClassDetails(response2.updatedClass);
      fetchStudents(response2.updatedClass.students);
      toast({
        title: "Student removed.",
        description: "The Student has been removed successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setStudentLoading(false);
    } catch (error) {
      console.error(error);
      toast({
        title: "Error deleting Student.",
        description: "There was an error deleting the Student.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  


  useEffect(() => {
    if (activeTab === 2 && classDetails && classDetails.teachers) {
      fetchTeachers(classDetails.teachers);
      fetchStudents(classDetails.students);
    }
    else if(activeTab === 1 && classDetails && classDetails.students){
        fetchProjects(classDetails.projects);
        fetchStudents(classDetails.students);
    }
    else if(activeTab === 0 && currentLoggedInTId){
      fetchCurrentUser(currentLoggedInTId);
    }
  }, [activeTab, classDetails]);

  const currentLoggedInTId = sessionStorage.getItem("CurrentLoggedInTId").trim();


  const handleAddStudentEmail = () => {
    if (!email || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      toast({
        description: 'Please enter a valid email address.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setInvitedStudentEmails((prevEmails) => [...prevEmails, email]);
    setEmail('');
  };

  const handleKeyPressStudent = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddStudentEmail();
    }
  };

  const handleAddTeacherEmail = () => {
    if (!email || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      toast({
        description: 'Please enter a valid email address.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setInvitedTeacherEmails((prevEmails) => [...prevEmails, email]);
    setEmail('');
  };

  const handleKeyPressTeacher = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddTeacherEmail();
    }
  };

  const handleRemoveStudentEmail = (emailToRemove) => {
    setInvitedStudentEmails((prevEmails) => prevEmails.filter((email) => email !== emailToRemove));
  };

  const handleRemoveTeacherEmail = (emailToRemove) => {
    setInvitedTeacherEmails((prevEmails) => prevEmails.filter((email) => email !== emailToRemove));
  };
  const [sendloading, setSendLoading] = useState(false);
  const handleInviteStudents = async () => {
    if (invitedStudentEmails.length === 0 && !file) {
      toast({
        description: 'Please enter at least one email address or upload file!',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setSendLoading(true);
  
    try {
      let studentIds;
      let mails
      if(!file){
      studentIds = await createStudents(invitedStudentEmails);
      }else if(file){
        mails = JSON.stringify(getEmails());
        mails = JSON.parse(mails);
        studentIds = await createStudents(mails)
      }
      await addStudentsToClass(classId, studentIds, token);
      const updatedStudentIds = [...classDetails.students, ...studentIds];
      fetchStudents(updatedStudentIds);
  
      toast({
        description: 'Students invited and added to the class successfully.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: 'Failed to invite students.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setSendLoading(false);
      onInviteStudentsClose();
      setInvitedStudentEmails([]);
    }
  };

  const handleInviteTeachers = async () => {
    if (invitedTeacherEmails.length === 0) {
      toast({
        description: 'Please enter at least one email address.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setSendLoading(true);
  
    try {
      const teacherIds = await createTeachers(invitedTeacherEmails);
      await addTeachersToClass(classId, teacherIds, token);
      const updatedTeacherIds = [...classDetails.teachers, ...teacherIds];
      fetchTeachers(updatedTeacherIds);
  
      toast({
        description: 'Teachers invited and added to the class successfully.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        description: 'Failed to invite teachers.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setSendLoading(false);
      onInviteTeachersClose();
      setInvitedTeacherEmails([]);
    }
  };

  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState('');
  const [newComment, setNewComment] = useState({});
  const [commentErrors, setCommentErrors] = useState({});
  const [showComments, setShowComments] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newClassDetails, setNewClassDetails] = useState({
    classname: '',
    academicYear: '',
    academicSemester: '',
    gradelevel: '',
    schedule: [], 
    status: 'active', 
  });
  

  const currentYear = new Date().getFullYear();
  const academicYears = [currentYear, currentYear + 1, currentYear + 2];
  const semesters = ["Spring", "Summer", "Fall", "Winter"];


  const handleSave = async () => {
    const storedSidebarData = sessionStorage.getItem("sideBarData");
    try {
      const response = await updateClassDetails(classId, newClassDetails);
      setClassDetails(response);
      onClose();
      toast({
        title: "Class details updated.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      if (storedSidebarData) {
        let sideBarData = JSON.parse(storedSidebarData);
        const classIndex = sideBarData?.classes?.findIndex((cls) => cls._id === classId);
        if (classIndex !== -1) {
          sideBarData.classes[classIndex].classname = newClassDetails?.classname;
          sessionStorage.setItem("sideBarData", JSON.stringify(sideBarData));
          const event = new Event('sidebarDataUpdated');
          window.dispatchEvent(event);
        }
      }
      
    } catch (error) {
      console.error('Error updating class details:', error);
      toast({
        title: "Error updating class details.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };


  useEffect(() => {
    if (isOpen && classDetails) {
      const [academicYear, academicSemester] = classDetails.term.split(' ');
      setNewClassDetails({
        classname: classDetails.classname || '',
        academicYear: academicYear || '',
        academicSemester: academicSemester || '',
        gradelevel: classDetails.gradelevel || '',
        schedule: classDetails.schedule.map((item) => ({
          ...item,
          startTime: item.startTime || '', 
          endTime: item.endTime || '' 
        })),
        status: classDetails.status || 'active',
      });
    }
  }, [isOpen, classDetails]);
  
  


  useEffect(() => {
    if (!classId) return;
  
    const announcementChannel = pusherClient.subscribe(`announcements-${classId}`);
  
    const handleNewAnnouncements = (newAnnouncements) => {
      const newAnnouncement = newAnnouncements.announcements[0]; 
      setAnnouncements(prevAnnouncements => {
        const announcementExists = prevAnnouncements.some(announcement => announcement._id === newAnnouncement._id);
        const updatedAnnouncements = announcementExists
          ? prevAnnouncements.map(announcement => 
              announcement._id === newAnnouncement._id ? newAnnouncement : announcement
            )
          : [...prevAnnouncements, newAnnouncement];
        const sortedData = updatedAnnouncements.sort((a, b) => new Date(b.time) - new Date(a.time));
  
        return sortedData;
      });
    };
  
    announcementChannel.bind('announcements:new', handleNewAnnouncements);
  
    return () => {
      announcementChannel.unbind('announcements:new', handleNewAnnouncements);
      pusherClient.unsubscribe(`announcements-${classId}`);
    };
  }, [classId]);
  

  const handleCommentChange = (announcementId, value) => {
    setNewComment(prevState => ({
      ...prevState,
      [announcementId]: value
    }));
  };

  const toggleComments = (id) => {
    setShowComments(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  

  useEffect(() => {
    fetchAnnouncements();
  }, [classId]);
  const fetchAnnouncements = async () => {
    try {
      const data = await fetchAnnouncementsForClass(classId);
      const sortedData = data.sort((a, b) => new Date(b.time) - new Date(a.time));
      setAnnouncements(sortedData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleCommentSubmit = async (announcementId) => {
    const comment = newComment[announcementId] || '';
  
  if (comment.trim() === '') {
    setCommentErrors(prevState => ({
      ...prevState,
      [announcementId]: 'Comment cannot be empty.'
    }));
    setTimeout(() => {
      setCommentErrors(prevState => ({
        ...prevState,
        [announcementId]: ''
      }));
    }, 2000); 

    return;
  }

  
    try {
      await addCommentToAnnouncement(announcementId, {
        userId: currentLoggedInTId,  
        comment: comment,
        userModel: "Teacher",
        classId: classId,
      });
      fetchAnnouncements(); 
      setNewAnnouncement(''); 
      setAnnouncementError(''); 
      setNewComment('');
      setCommentErrors({});
    } catch (error) {
      console.error('Error submitting comment:', error);
      
    }
  };
  const [postcommentloading, setPostCommentLoading] = useState(false);
  const handleAnnouncementSubmit = async () => {
    if (newAnnouncement.trim() === '') {
      setAnnouncementError('Announcement cannot be empty.');
      setTimeout(() => {
        setAnnouncementError('');
      }, 2000); 
      return;
    }
  
    try {
      setPostCommentLoading(true);
      
      await addAnnouncementToClass(classId, {
        userId: currentLoggedInTId,  
        announcement: newAnnouncement,
        comments: [],
        userModel: "Teacher",
      });
      fetchAnnouncements(); 
      setNewAnnouncement(''); 
      setAnnouncementError(''); 
    } catch (error) {
      console.error('Error submitting announcement:', error);
      
    }finally {
      setPostCommentLoading(false); 
    }
  };

  const [file, setFile] = useState<File | null>(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv']
    },
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      csvParser(acceptedFiles[0]);
    }
  });

  

  const [parsedData,setParsedData] = useState(null);

  const getEmails = () => {
    if (parsedData) {
      return parsedData.map(row => {
        if (row['Email Address']) {
          return row['Email Address'];
        }
        return null;
      }).filter(entry => entry !== null);
    }
    return [];
  };

  const getNames = () => {
    if (parsedData) {
      return parsedData.map(row => {
        if (row['Email Address']) {
          return row['First Name'] + ' ' + row['Last Name'];
        }
        return null;
      }).filter(entry => entry !== null);
    }
    return [];
  };

  const csvParser = (files) =>{
    console.log(files)
    Papa.parse(files,{
      complete:function(results){
          console.log(results.data)
          setParsedData(results.data);
      },
      header:true, 
      skipEmptyLines:true,
    })
  };


  


  if (loading) {
    return (
      <Box>
        <LoadingSpinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }

  const cleanPhotoURL = (url) => {
    const plink = url.replace(/['"]/g, '');  
    return plink;
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  const handleTabChange = (index) => {
    setActiveTab(index);
    sessionStorage.setItem("activeTabInClass", index.toString());
  };

  const hasChanges = () => {
    if(classDetails &&  newClassDetails){
    const { classname, gradelevel, schedule, status } = classDetails;
    const [academicYear, academicSemester] = classDetails?.term.split(' ');
    const { classname: newClassname, academicYear: newAcademicYear, academicSemester: newAcademicSemester, gradelevel: newGradelevel, schedule: newSchedule, status: newStatus } = newClassDetails;
  
    // Compare primitive values directly
    if (
      classname !== newClassname ||
      academicYear !== newAcademicYear ||
      academicSemester !== newAcademicSemester ||
      gradelevel !== newGradelevel ||
      status !== newStatus
    ) {
      return true;
    }
  
    if (schedule.length !== newSchedule.length) return true;
  
    for (let i = 0; i < schedule.length; i++) {
      const oldSchedule = schedule[i];
      const newSched = newSchedule[i];

      if (
        oldSchedule.day !== newSched.day ||
        oldSchedule.startTime !== newSched.startTime ||
        oldSchedule.endTime !== newSched.endTime
      ) {
        return true;
      }
    }
  
    return false;
  }
  };
  


  return (
    <>
      <Box width={'full'}>
      <Tabs index={activeTab} onChange={handleTabChange}>
          <TabList mb="1em">
            <Tab>Announcements</Tab>
            <Tab>Projects</Tab>
            <Tab>People</Tab>
          </TabList>
          <TabPanels>
          <TabPanel>
          
          <Box p={4} ml={-4}>
          <Box position="relative" w="full" h="200px" bg="gray.200" display="flex" alignItems="center" justifyContent="center" mb={6}>
  <Image borderRadius="md" objectFit="cover" w="full" h="full" src={classDetails?.url} alt={classDetails?.classname} />
  <Box position="absolute" bottom={2} left={2} color="white" fontWeight="bold" fontSize="lg" display="flex" flexDirection="column" alignItems="flex-start">
    <Text>{classDetails?.classname}</Text>
    <Text fontSize="md">{classDetails?.term}</Text>
  </Box>
  <IconButton
      position="absolute"
      bottom={2}
      right={2}
      colorScheme="whiteAlpha"
      icon={<EditIcon />}
      onClick={onOpen} aria-label={''}  />
</Box>

  <Flex>
    

    <Box
      w="100%"
      p={4}
      bg="white"
    >
      <Box mb={4} p={4} borderWidth="1px" borderRadius="lg" boxShadow={"md"} bg="white">
        {/* <Heading size="sm" mb={4}>Create Announcement</Heading>  */}
        <Textarea
          placeholder="Write a new announcement..."
          value={newAnnouncement}
          onChange={(e) => {
            setNewAnnouncement(e.target.value);
            setAnnouncementError(''); 
          }}
          isInvalid={announcementError.length > 0}
          errorBorderColor="red.300"
        />
        <Text color="red.500" mt={2}>{announcementError}</Text>
        <Button
        mt={2}
        _hover={{ bg: "purple.900", color: "white" }}
        onClick={handleAnnouncementSubmit}
        cursor="pointer"
        isDisabled={postcommentloading}
      >
        {postcommentloading ? "Posting..." : "Post"}
      </Button>
      </Box>

      <Box width="100%">
      <VStack spacing={4} align="start">
        {announcements.map(announcement => (
          <Box
            width="100%"
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="md"
            key={announcement._id}
            bg="white"
          >
            <Flex align="center" mb={2}>
              <Avatar src={cleanPhotoURL(announcement.user.photoURL)} size="sm" mr={2} />
              <Box>
                <Text fontSize="md" fontWeight="bold">
                  {announcement.user.name}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {formatDate(announcement.time)}
                </Text>
              </Box>
            </Flex>
            <Text fontSize="sm" fontWeight="normal">
              {announcement.announcement}
            </Text>
            <br />
            <Divider my={4} borderWidth={1} width="calc(100% + 2rem)" ml={-4} />
            {announcement.comments.length > 0 && (
              <Button
              mt={2}
              size={'md'}
              cursor="pointer"
              onClick={() => toggleComments(announcement._id)}
            >
              Class comments
            </Button>
            )}
            {showComments[announcement._id] && announcement.comments.length > 0 && (
              <VStack spacing={2} mt={2} align="start">
                {announcement.comments.map((comment, index) => (
                  <Box key={index} width="100%" mt={2}>
                  <Flex align="center" mb={2}>
                    <Avatar src={cleanPhotoURL(comment.user.photoURL)} size="sm" mr={2} />
                    <Box>
                    <Flex>
                      <Text fontSize="sm" fontWeight="bold">
                        {comment.user.name}
                      </Text>
                      <Text ml={2} fontSize="sm" color="gray.500">
                        {formatDate(comment.time)}
                      </Text>
                      </Flex>
                      <Text>{comment.comment}</Text>
                    </Box>
                  </Flex>
                </Box>
                ))}

              </VStack>
            )}
            <Box width="100%" mt={4}>
              <Textarea
                placeholder="Write a comment..."
                value={newComment[announcement._id] || ''}
                onChange={(e) => {
                  handleCommentChange(announcement._id, e.target.value);
                  setCommentErrors(prevState => ({
                    ...prevState,
                    [announcement._id]: ''
                  }));
                }}
                isInvalid={commentErrors[announcement._id]?.length > 0}
                errorBorderColor="red.300"
              />
              {commentErrors[announcement._id] && (
                <Text color="red.500" mt={2}>
                  {commentErrors[announcement._id]}
                </Text>
              )}
              <Button
                _hover={{ background: 'purple.900', color: 'white' }}
                mt={2}
                onClick={() => handleCommentSubmit(announcement._id)}
              >
                Comment
              </Button>
            </Box>
          </Box>
        ))}
      </VStack>
    </Box>


    </Box>
  </Flex>
</Box>
</TabPanel>
            <TabPanel>
              <Box>
                <Flex mb={2} justify="space-between" align="center">
                  <Heading size="md">Projects</Heading>
                </Flex>

                {projectLoading ? (<LoadingSpinner />) : projects.length === 0 ? (
                  <Flex direction="column" align="center" justify="center" height="200px">
                    <AiOutlineExclamationCircle size={40} color="gray.500" />
                    <Text mt={4} fontSize="lg" color="gray.500">
                      No projects found
                    </Text>
                  </Flex>
                ) : (
                  <Flex direction="column" alignItems="start" mx="2" ml={-4}>
        <Box p={3}>
          <SimpleGrid columns={[1, 2, 3]} spacing={2}>
            {projects?.map((project, index) => (
              <ProjectCard key={project._id} project={project} classId={classDetails._id} index={index} />
            ))}
          </SimpleGrid>
        </Box>
      </Flex>
                    
                )}
              </Box>
            </TabPanel>
            <TabPanel>
              <Box p={4}>
                <Heading size="md" mb={4}>People</Heading>
                <Tabs variant="soft-rounded" colorScheme="purple">
                  <TabList>
                    <Tab>Teachers</Tab>
                    <Tab>Students</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                    <Flex justify="space-between" mb={4} align="center">
                        <Heading></Heading>
                        <Button
                        leftIcon={<AddIcon />}
                        bg="purple.900"
                        color="white"
                        _hover={{ bg: 'purple.800' }}
                        onClick={onInviteTeachersOpen}
                        >
                        Invite Teachers
                        </Button>
                    </Flex>
                    <VStack spacing={4} align="start">
      {teacherLoading ? (
        <LoadingSpinner />
      ) : teachers.length === 0 ? (
        <Text>No teachers found.</Text>
      ) : (
        teachers.map((teacher) => (
          <Flex
            key={teacher._id}
            p={4}
            borderWidth="1px"
            borderRadius="lg"
            align="center"
            width="full"
            justify="space-between" // Add space between to push menu button to the right
          >
            <Flex align="center">
              <Avatar name={teacher.name} src={teacher.photoURL} />
              <Box ml={4}>
                <Text fontWeight="bold">{teacher.name}</Text>
                <Text>{teacher.email}</Text>
              </Box>
            </Flex>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<BiDotsVerticalRounded />}
                size={"md"}
                bg={"white"}
              />
              <MenuList>
                <MenuItem onClick={() => handleDelete(teacher._id)}>Delete</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ))
      )}
    </VStack>
                    </TabPanel>
                    <TabPanel>
                      <Flex justify="space-between" mb={4} align="center">
                        <Heading></Heading>
                        <Button
                          leftIcon={<AddIcon />}
                          bg="purple.900"
                          color="white"
                          _hover={{ bg: 'purple.900' }}
                          onClick={onInviteStudentsOpen}
                        >
                          Invite Students
                        </Button>
                      </Flex>
                      <VStack spacing={4} align="start">
                      {studentLoading ? (
                        <LoadingSpinner />
                      ) : students.length === 0 ? (
                        <Text>No students found.</Text>
                      ) : (
                        students.map((student) => (
                          <Flex
                            key={student._id}
                            p={4}
                            borderWidth="1px"
                            borderRadius="lg"
                            align="center"
                            width="full"
                            justify="space-between" 
                          >
                            <Flex align="center">
                              <Avatar name={student.name} src={student.photoURL} />
                              <Box ml={4}>
                                <Text fontWeight="bold">{student.name}</Text>
                                <Text>{student.email}</Text>
                              </Box>
                            </Flex>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                aria-label="Options"
                                icon={<BiDotsVerticalRounded />}
                                size={"md"}
                                bg={"white"}
                              />
                              <MenuList>
                                <MenuItem onClick={() => handleDeleteStudent(student._id)}>Delete</MenuItem>
                              </MenuList>
                            </Menu>
                          </Flex>
                        ))
                      )}
                    </VStack>

                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* Invite Students Modal */}
      <Modal isOpen={isInviteStudentsOpen} onClose={onInviteStudentsClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite Students</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto" maxHeight="400px">
          <Tabs >
            <TabList justifyContent={"center"} textAlign={"center"} alignItems={"center"} width="100%">
              <Tab>Invite Students</Tab>
              <Tab>CSV Upload</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <FormControl mb={4}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyPressStudent}
                    placeholder="Enter student's email"
                  />
                </FormControl>
                <Button
                  onClick={handleAddStudentEmail}
                  bg="purple.900"
                  color="white"
                  _hover={{ bg: 'purple.900' }}
                >
                  Add Email
                </Button>
                <Stack mt={4} spacing={2}>
                  {invitedStudentEmails.map((email, index) => (
                    <Tag
                      size="md"
                      key={index}
                      borderRadius="md"
                      border="1px"
                      variant="solid"
                      color="purple.900"
                      bg="white"
                    >
                      <TagLabel>{email}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveStudentEmail(email)} />
                    </Tag>
                  ))}
                </Stack>
              </TabPanel>
              <TabPanel>
                <FormControl>
                  <FormLabel>Upload CSV</FormLabel>
                  <Box {...getRootProps()} border="1px" borderColor="gray.300" p={4} textAlign="center">
                    <input {...getInputProps()} />
                    {file ? (
                      <Text>{file.name}</Text>
                    ) : (
                      <Text cursor="pointer">Drag 'n' drop a CSV file here, or click to select one</Text>
                    )}
                  </Box>
                </FormControl>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
        <Button
      bg="purple.900"
      color="white"
      _hover={{ bg: 'purple.800' }}
      onClick={handleInviteStudents}
      isLoading={sendloading}
      loadingText="Sending..."
      isDisabled={sendloading}
    >
      {sendloading ? <LoadingSpinner /> : "Send Invitations"}
    </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

      {/* Invite Teachers Modal */}
      <Modal isOpen={isInviteTeachersOpen} onClose={onInviteTeachersClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite teachers</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" maxHeight="400px">
            <FormControl mb={4}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyPressTeacher}
                placeholder="Enter teacher's email"
              />
            </FormControl>
            <Button
              onClick={handleAddTeacherEmail}
              bg="purple.900"
              color="white"
              _hover={{ bg: 'purple.800' }}
            >
              Add Email
            </Button>
            <Stack mt={4} spacing={2}>
              {invitedTeacherEmails.map((email, index) => (
                <Tag
                  size="md"
                  key={index}
                  borderRadius="md"
                  border="1px"
                  variant="solid"
                  color="purple.900"
                  bg="white"
                >
                  <TagLabel>{email}</TagLabel>
                  <TagCloseButton onClick={() => handleRemoveTeacherEmail(email)} />
                </Tag>
              ))}
            </Stack>
          </ModalBody>
          <ModalFooter>
          <Button
          bg="purple.900"
          color="white"
          _hover={{ bg: 'purple.800' }}
          onClick={handleInviteTeachers}
          isLoading={sendloading}
          loadingText="Sending..."
          isDisabled={sendloading}
        >
          {sendloading ? <LoadingSpinner /> : "Send Invitations"}
        </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Edit Class Details</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <VStack spacing={4} align="stretch">
        <SimpleGrid columns={2} spacing={6}>
          {/* Left Column: Class Name and Grade Level */}
          <Box>
            {/* Class Name */}
            <FormControl>
            {newClassDetails.classname ? (
            <FormLabel>Class Name</FormLabel>
          ) : (
            <Box height="32px" /> 
          )}
              <Input
                variant="flushed"
                placeholder="Enter Class Name"
                value={newClassDetails.classname}
                onChange={(e) =>
                  setNewClassDetails((prev) => ({
                    ...prev,
                    classname: e.target.value,
                  }))
                }
                onFocus={(e) => (e.target.placeholder = "Class Name")}
                onBlur={(e) => (e.target.placeholder = "Enter Class Name")}
              />
            </FormControl>

            {/* Grade Level */}
            <FormControl mt={4}>
            {newClassDetails.gradelevel ? (
            <FormLabel>Grade Level</FormLabel>
          ) : (
            <Box height="32px" /> 
          )}
              <Input
                variant="flushed"
                placeholder="Enter Grade Level"
                value={newClassDetails.gradelevel}
                onChange={(e) =>
                  setNewClassDetails((prev) => ({
                    ...prev,
                    gradelevel: e.target.value,
                  }))
                }
                onFocus={(e) => (e.target.placeholder = "Grade Level")}
                onBlur={(e) => (e.target.placeholder = "Enter Grade Level")}
              />
            </FormControl>
          </Box>

          {/* Right Column: Academic Year and Academic Semester */}
          <Box>
            {/* Academic Year */}
            <FormControl>
              {newClassDetails.academicYear && <FormLabel>Academic Year</FormLabel>}
              <Select
                variant="flushed"
                value={newClassDetails.academicYear}
                onChange={(e) =>
                  setNewClassDetails((prev) => ({
                    ...prev,
                    academicYear: e.target.value,
                  }))
                }
                cursor={'pointer'}
              >
                {academicYears.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </FormControl>

            {/* Academic Semester */}
            <FormControl mt={4}>
              {newClassDetails.academicSemester && <FormLabel>Academic Semester</FormLabel>}
              <Select
                variant="flushed"
                value={newClassDetails.academicSemester}
                onChange={(e) =>
                  setNewClassDetails((prev) => ({
                    ...prev,
                    academicSemester: e.target.value,
                  }))
                }
                cursor={'pointer'}
              >
                {semesters.map((semester) => (
                  <option key={semester} value={semester}>
                    {semester}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </SimpleGrid>

        {/* Schedule Section */}
        <FormControl>
          <FormLabel>Schedule</FormLabel>
          <Box border="1px" borderColor="gray.300" p={3} borderRadius="md">
          <FormLabel mb={2}>Days</FormLabel>
            <SimpleGrid columns={7} spacing={2}>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <Button
                  key={day}
                  width={95}
                  variant={newClassDetails.schedule.some(item => item.day === day) ? 'solid' : 'outline'}
                  color={newClassDetails.schedule.some(item => item.day === day) ? 'purple.900' : 'purple.900'}
                  bg={newClassDetails.schedule.some(item => item.day === day) ? 'gray.300' : 'transparent'}
                  _hover={{
                    bg: newClassDetails.schedule.some(item => item.day === day) ? 'gray.300' : 'gray.100',
                  }}
                  onClick={() => {
                    const updatedSchedule = [...newClassDetails.schedule];
                    if (updatedSchedule.some(item => item.day === day)) {
                      const index = updatedSchedule.findIndex(item => item.day === day);
                      updatedSchedule.splice(index, 1); // Remove day if already selected
                    } else {
                      updatedSchedule.push({ day, startTime: newClassDetails.startTime, endTime: newClassDetails.endTime });
                    }
                    setNewClassDetails(prev => ({
                      ...prev,
                      schedule: updatedSchedule
                    }));
                  }}
                >
                  {day}
                </Button>
              ))}
            </SimpleGrid>
            <HStack spacing={2} mt={4}>
            {/* Start Time Label and Input */}
            <FormControl>
              <Input
                type="time"
                variant="flushed"
                value={newClassDetails.schedule[0]?.startTime || ""}
                onChange={(e) => {
                  const updatedStartTime = e.target.value;
                  setNewClassDetails(prev => ({
                    ...prev,
                    schedule: prev.schedule.map(item => ({
                      ...item,
                      startTime: updatedStartTime
                    }))
                  }));
                }}
                cursor={'text'}
              />
              <FormLabel mt={2}>Start Time</FormLabel>
            </FormControl>

 

            {/* End Time Label and Input */}
            <FormControl>
              
              <Input
                type="time"
                variant="flushed"
                value={newClassDetails.schedule[0]?.endTime || ""}
                onChange={(e) => {
                  const updatedEndTime = e.target.value;
                  setNewClassDetails(prev => ({
                    ...prev,
                    schedule: prev.schedule.map(item => ({
                      ...item,
                      endTime: updatedEndTime
                    }))
                  }));
                }}
                cursor={'text'}
              />
              <FormLabel mt={2}>End Time</FormLabel>
            </FormControl>
          </HStack>

          </Box>
        </FormControl>

        {/* Status Section */}
        <FormControl>
          {newClassDetails.status && <FormLabel>Status</FormLabel>}
          <Select
            variant="flushed"
            value={newClassDetails.status}
            onChange={(e) =>
              setNewClassDetails((prev) => ({
                ...prev,
                status: e.target.value,
              }))
            }
            cursor={'pointer'}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="remove">Remove</option>
          </Select>
        </FormControl>
      </VStack>
    </ModalBody>

    <ModalFooter>
      <Button
        bg={"purple.900"}
        color={"white"}
        mr={3}
        _hover={{ bg: "purple.800" }}
        isDisabled={!hasChanges()} // Disable save button until there's a change
        onClick={handleSave}
      >
        Save
      </Button>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>



    </>
  );
};

export default ClassView;

