import {
    Box,
    Drawer,
    DrawerContent,
    useDisclosure,
    Flex,
    Text,
  } from "@chakra-ui/react";
  import MobileNav from "../../sidebar/MobileNav.tsx";
  //import SideNav from "../../sidebar/SideBar.tsx";
  import { SideNav } from "../../Sidebar1/Sidebar.tsx";
  import { ReactNode, useEffect, useMemo, useState } from "react";
  import { TbInfoTriangle } from "react-icons/tb";
  import { RxCross2 } from "react-icons/rx";
  import axios from "axios";
import React from "react";
  //import { PUBLIC_URL } from "../common/utils";
  //import { prepareAnnouncements } from "../common/prepare-data";
  
  interface DashboardProps {
    title?: ReactNode;
    children?: ReactNode;
  }
  
  const TDashboard = (props: DashboardProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { children } = props;
  
    // useEffect(() => {
    // //   const loggedin = localStorage.getItem("isActive") === "ACTIVE";
    // //   setIsLoggedIn(loggedin);
    // }, [localStorage.getItem("isActive")]);
  
    return (

      <Flex>
          <SideNav />
          <Flex
            direction="column"
            ml="280px"
            w="calc(100% - 280px)"
            minH="100vh"
          >
           <MobileNav onOpen={onOpen} />
            <Flex
              as="main"
              p="6"
              mt="64px"
            >
              {children}
            </Flex>
          </Flex>
        </Flex>


      // <Flex>
      //   <SideNav
      //   />
      //   {/* <Drawer
      //     isOpen={isOpen}
      //     placement="left"
      //     onClose={onClose}
      //     returnFocusOnClose={false}
      //     onOverlayClick={onClose}
      //     size="full"
      //   >
      //     <DrawerContent>
      //       <SideNav onClose={onClose} />
      //     </DrawerContent>
      //   </Drawer> */}
      //   <MobileNav onOpen={onOpen} />
      //   <Box ml={{ base: 0, md: "18rem" }} p="4">
      //     {children}
      //   </Box>
      // </Flex>
    ) 

  };
  
  export default TDashboard;
  