import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionModalContent = motion(ModalContent);

const SessionExpiryModal = ({ isOpen, onRefresh,  onLogout, onClose }) => {
  const [timeLeft, setTimeLeft] = useState(120);
  const navigate = useNavigate();


  useEffect(() => {
    if (!isOpen) return;
  
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          onClose();
          sessionStorage.clear();
          navigate('/'); 
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  
    return () => clearInterval(interval);
  }, [isOpen,onClose, navigate]);
  

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Modal isOpen={isOpen} onClose={onLogout} isCentered>
      <ModalOverlay />
      <MotionModalContent
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <ModalHeader>Session Expiry Warning</ModalHeader>
        <ModalBody>
          <Text fontSize="lg" textAlign="center" mb={4}>
            Your session will expire in {formatTime(timeLeft)}
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button bg={'purple.900'} _hover={'purple.900'} color={'white'} onClick={onRefresh} mr={3}>
            Refresh session
          </Button>
          <Button variant="outline"  onClick={onLogout}>
            Logout
          </Button>
        </ModalFooter>
      </MotionModalContent>
    </Modal>
  );
};

export default SessionExpiryModal;
