import { Avatar, Box, Flex, Text, VStack, Badge, Input, IconButton } from '@chakra-ui/react';
import { User } from '../data/dummyData.ts';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { SearchIcon } from '@chakra-ui/icons';
import avatar from '../../Assets/userImage.png';
import logounichat from "../../Assets/G-Ideas.png";
import { LoadingSpinner } from '../../commons/LoadingSpinner.tsx';

const MotionFlex = motion(Flex);

export const UserPanel = ({ users, name, onUserClick, selectedUser, userM }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  useEffect(() => {
    setCurrentSelectedUser(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    setFilteredUsers(
      users.filter(user =>
        user.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };
  
  const handleUserClick = (user) => {
    setCurrentSelectedUser(user);
    onUserClick(user);
  };

  const [loading, setLoading] = useState(true);

  return (
    <Box
      w="300px"
      h="full"
      bg="white"
      borderRight="1px"
      borderColor="gray.200"
      boxShadow={"sm"}
      p={4}
      overflowY="auto"
    >
      {/* Sticky Header Section */}
      <Box position="sticky" top={0} bg="white" zIndex={1} p={4}>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Chats
        </Text>

        {/* Search Bar */}
        <Box mb={4}>
          <Input
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search Users..."
            size="sm"
            bg="gray.50"
            borderRadius="lg"
            _focus={{ borderColor: 'blue.500' }}
          />
        </Box>
      </Box>
      <Box
        h="calc(100% - 140px)"  
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none', 
          'scrollbar-width': 'none', 
        }}
      >
        <VStack spacing={2} align="stretch">
            <MotionFlex
                    p={3}
                    alignItems="center"
                    gap={3}
                    cursor="pointer"
                    borderRadius="lg"
                    bg={currentSelectedUser === null ?  'blue.50' : 'transparent'}
                    _hover={{ bg: 'gray.50' }}
                    onClick={() =>  handleUserClick(null)}
                    transition="all 0.2s"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <Avatar size="sm" src={logounichat} name={'Group Chat'} />
                <Box flex={1}>
                  <Flex justify={'space-between'} align="center">
                    <Text fontWeight="medium">{name}</Text>
                    {userM['groupChat']?.unreadCount > 0 && currentSelectedUser !== null &&(
                    <Badge colorScheme="blue" borderRadius="full">
                      {userM['groupChat']?.unreadCount || 0}
                      </Badge>
                    )}
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text fontSize="sm" color="gray.500" noOfLines={1}>
                     Group chat
                    </Text>
                  </Flex>
                </Box>
                </MotionFlex>

          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <Flex
                key={user.id}
                p={3}
                alignItems="center"
                gap={3}
                cursor="pointer"
                borderRadius="lg"
                bg={currentSelectedUser?._id === user._id ?  'blue.50' : 'transparent'}
                _hover={{ bg: 'gray.50' }}
                onClick={() =>  handleUserClick(user)}
                transition="all 0.2s"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Avatar
                  size="sm"
                  src={user.photoUrl || user.photoURL || avatar}
                >
                </Avatar>
                <Box flex={1}>
                  <Flex justify={'space-between'} align="center">
                    <Text fontWeight="medium">{user.name}</Text>
                    {userM[user._id]?.unreadCount > 0 && currentSelectedUser?._id!==user._id && (
                    <Badge colorScheme="blue" borderRadius="full">
                      {userM[user._id]?.unreadCount || 0}
                      </Badge>
                    )}
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Text fontSize="sm" color="gray.500" noOfLines={1}>
                      {user.email}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            ))
          ) : (
            <Text color="gray.500" textAlign="center" mt={4}>
              No users found
            </Text>
          )}
        </VStack>
      </Box>
    </Box>
  );
};
