import { axiosInstance } from '../../../axiosUtils.ts';


export const getChatGPTResponse = async (messages) => {
    try {
      const response = await axiosInstance.post('/api/teacher/getChatGPTResponse', { messages });
      return response.data;
    } catch (error) {
      console.error('Error getting chatGPT message:', error);
      throw new Error('Failed to get chatGPT message:');
    }
  };

export const getChatGPTResponseForSRS = async (messages) => {
    try {
      const response = await axiosInstance.post('/api/student/getChatGPTResponseforsrs', { messages });
      return response.data;
    } catch (error) {
      console.error('Error getting chatGPT message:', error);
      throw new Error('Failed to get chatGPT message:');
    }
  };

export const appendGPT = async (messages, tId) => {
    try {
      const response = await axiosInstance.post('/api/teacher/storeChatGPTResponse', { messages, tId });
      return response;
    } catch (error) {
      console.error('Error storing chatGPT message:', error);
      throw new Error('Failed to store chatGPT message');
    }
};
