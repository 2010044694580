import { Avatar, Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { AudioPlayer } from './AudioPlayer.tsx';
import React from 'react';
import avatar from '../../Assets/userImage.png';

const MotionFlex = motion(Flex);

export const Message = ({ message, currentuser, selectedUser, userM }) => {


  // Function to resize emojis
  function resizeEmojis(string: string) {
    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    return string?.replace(regex, (match) => {
      return `<span style="font-size: 2rem; padding:3px; line-height: 1;">${match}</span>`;
    });
  }

  return (
    <MotionFlex
      initial={{ opacity: 0, x: message.sender === currentuser ? 20 : -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: message.sender === currentuser ? 20 : -20 }}
      transition={{ type: 'spring', stiffness: 200, damping: 20 }}
      mb={4}
      w="full"
      justify={message.sender === currentuser ? 'flex-end' : 'flex-start'}
      gap={3}
      
    >
      {message.sender !== currentuser && (
        <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.2 }}>
           <Tooltip label={userM[message.sender]?.firstName+' '+userM[message.sender]?.lastName} aria-label="Sender name">
          <Avatar
                              size="sm"
                              src={userM[message.sender]?.photoUrl || userM[message.sender]?.photoURL || avatar}
                            />
         </Tooltip>
        </motion.div>
       
      )}
      <Box
        maxW="70%"
        bg={message.sender === currentuser ? 'blue.500' : 'white'}
        color={message.sender === currentuser ? 'white' : 'gray.800'}
        p={4}
        borderRadius={message.sender === currentuser ? '2xl 2xl 0 2xl' : '2xl 2xl 2xl 0'}
        boxShadow="lg"
        position="relative"
        _before={{
          content: '""',
          position: 'absolute',
          bottom: '0',
          [message.sender === currentuser ? 'right' : 'left']: '-10px',
          borderStyle: 'solid',
          borderWidth: '10px',
          borderColor: `transparent transparent ${message.sender === currentuser ? 'var(--chakra-colors-blue-500)' : 'white'} transparent`,
        }}
      >
        {selectedUser === null ? (
       <Text fontSize="sm" fontWeight="bold" mb={1}>
            {message.sender !== currentuser ? userM[message.sender]?.name : ''}
       </Text>):null
}

        <Text fontSize="md" dangerouslySetInnerHTML={{ __html: resizeEmojis(message.message) }} />
        <Text fontSize="xs" color={message.sender === currentuser ? 'whiteAlpha.700' : 'gray.500'} mt={2} textAlign="right">
          {new Date(message.sentTime).toLocaleString()}
        </Text>
      </Box>
      {message.sender === currentuser && (
        <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.2 }}>
           <Avatar
                              size="sm"
                              src={userM[message.sender]?.photoUrl || userM[message.sender]?.photoURL || avatar}
                            />
        </motion.div>
      )}
    </MotionFlex>
  );
};
