import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Text,
    InputGroup,
    InputLeftElement,
    Input,
    Icon,
    Spinner,
    IconButton,
  } from '@chakra-ui/react';
  import { Search } from 'lucide-react';
  import React, { useEffect, useState } from 'react';
  import ADashboard from '../AdminDashboard/ADashboard.tsx';
  import { getStudents } from '../../../Services/Admin/AdminService.tsx';
  import { FiTrash } from 'react-icons/fi'; 
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';
  
  function Students() {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const isAdmin = sessionStorage.getItem('isAdmin');
  
    useEffect(() => {
      const fetchStudents = async () => {
        try {
          setLoading(true);
          const students = await getStudents(isAdmin);
          setStudents(students);
          setFilteredStudents(students);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching students:', error);
          setLoading(false);
        }
      };
      fetchStudents();
    }, [isAdmin]);
  
    const handleSearch = (e) => {
      const query = e.target.value.toLowerCase();
      setSearchTerm(query);
  
      if (query === '') {
        setFilteredStudents(students);
      } else {
        const filtered = students.filter((student) => {
          const nameMatch = student.name && student.name.toLowerCase().includes(query);
          const emailMatch = student.email && student.email.toLowerCase().includes(query);
          const classNamesMatch = student.classNames && student.classNames.some(className => 
            className.toLowerCase().includes(query)
          );
  
          return nameMatch || emailMatch || classNamesMatch;
        });
  
        setFilteredStudents(filtered);
      }
    };
  
    return (
      <ADashboard>
        <Box mt={24} ml={6} mr={6}>
          <Text fontSize="2xl" fontWeight="bold" mb={8}>
            Manage Students
          </Text>
  
          <Box mb={6}>
            <InputGroup maxW="400px">
              <InputLeftElement>
                <Icon as={Search} color="gray.400" />
              </InputLeftElement>
              <Input
                placeholder="Search students..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </InputGroup>
          </Box>
  
          <Box
            bg="white"
            rounded="lg"
            shadow="sm"
            borderWidth="1px"
            maxH="550px"
            overflowY="auto"
          >
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <LoadingSpinner />
              </Box>
            ) : filteredStudents.length === 0 ? (
              <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <Text fontSize="lg" fontWeight="medium" color="gray.500">No data found</Text>
              </Box>
            ) : (
              <Table variant="simple">
                <Thead position="sticky" top="0" bg="white" zIndex="1">
                  <Tr>
                    <Th width="25%">Name</Th>
                    <Th width="30%">Email</Th>
                    <Th width="30%">Classes</Th>
                    <Th width="15%" textAlign={'center'}>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredStudents.map((student) => (
                    <Tr key={student.email}>
                      <Td width="25%" fontWeight="medium">{student.name || "N/A - No account created"}</Td>
                      <Td width="30%">{student.email}</Td>
                      <Td width="30%">{student.classNames.join(', ')}</Td>
                      <Td width="15%" textAlign="center">
                        <IconButton
                          icon={<FiTrash />}
                          aria-label="Delete student"
                          color={'purple.900'}
                          size="lg"
                          bg={'white'}
                          onClick={() => alert('Delete functionality to be implemented')}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </Box>
        </Box>
      </ADashboard>
    );
  }
  
  export default Students;
  