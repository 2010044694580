import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import SDashboard from './components/student-portal/Dashboard/Dashboard.tsx';
import TDashboard from './components/teacher-portal/Dashboard/Dashboard.tsx';
import TLogin from './components/teacher-portal/Login/Login.tsx';
import SLogin from './components/student-portal/Login/Login.tsx'; 
import ProtectedRoute from './components/protectedRoute/protectedRoute.tsx';
import StudentProtectedRoute from './components/protectedRoute/StudentProtectedRoute.tsx';
import HomeFrame1 from './components/teacher-portal/HomeFrame/HomeFrame.tsx';
import ClassView1 from './components/student-portal/ClassView/ClassView.tsx';
import ClassView2 from './components/teacher-portal/ClassView/ClassView.tsx';
import ProjectView2 from './components/teacher-portal/ProjectView/ProjectView.tsx';
import ProjectView1 from './components/student-portal/ProjectView/ProjectView.tsx';
import Message1 from './components/student-portal/Messaging/Message.tsx';
import Message2 from './components/teacher-portal/Messaging/Message.tsx';
import { AuthProvider } from './contexts/AuthContext.tsx';
import { StudentAuthProvider } from './contexts/StudentAuthContext.tsx';
import HomeFrame2 from './components/student-portal/HomeFrame/HomeFrame.tsx';
import LandingPage from './components/Landingpage/LandingPage.jsx';
import GroupView2 from './components/teacher-portal/GroupView/GroupView.tsx';
import GroupView1 from './components/student-portal/GroupView/GroupView.tsx';
import ChatGPTHistory1 from './components/student-portal/GPTHistory/GPTHistory.tsx'
import ChatGPTHistory2 from './components/teacher-portal/GPTHistory/GPTHistory.tsx'
import './App.css'
import LearnQ from './components/student-portal/LearnQ/Home.jsx';
import LearnQ2 from './components/teacher-portal/LearnQ/Home.jsx';
import Config from './components/teacher-portal/Config/Home.jsx';
import AdminProtectedRoute from './components/protectedRoute/AdminProtectedRoute.tsx'
import ALogin from './components/admin-portal/Login/Login';
import Adenied from './components/admin-portal/AccessDenied/AccessDenied.jsx';

import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import Dashboard from './components/admin-portal/Home/Dashboard.tsx';
import TeacherRequests from './components/admin-portal/TeacherRequests/TeacherRequests.tsx';
import Teachers from './components/admin-portal/Teachers/Teachers.tsx';
import Students from './components/admin-portal/Students/Students.tsx';
import Classes from './components/admin-portal/Classes/Classes.tsx';
import Processing from './components/Processing/page.tsx';
import SessionExpiryModal from './expiryModal.jsx';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { auth } from './config.js';
import { setupStorageListener } from './axiosUtils.ts';
import { Flex, useToast } from "@chakra-ui/react";
import Chat from './components/chat/Chat.tsx';
import { SideNav } from './components/Sidebar1/Sidebar.tsx';
import { Header } from './components/Sidebar1/Header.tsx';
import MobileNav from './components/sidebar/MobileNav.tsx';


function App() {
  const [isModalOpen, setModalOpen] = useState(false);
  const location = useLocation(); 
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const cleanup = setupStorageListener();
    return () => {
      cleanup(); 
    };
  }, []);

  const handleRefreshToken = async () => {
    try {
      
      const currentUser = auth.currentUser;
      const userType = sessionStorage.getItem("userType");
      let tokenExpirationKey = null;
      let at = null;

      if(userType){
      switch (userType) {
        case "teacher":
          tokenExpirationKey = "TokenExpirationTime";
          at = "googleAccessToken";
          break;
        case "student":
          tokenExpirationKey = "studentTokenExpirationTime";
          at = "studentGoogleAccessToken";
          break;
        case "admin":
          tokenExpirationKey = "AdminTokenExpirationTime";
          at="adminGoogleAccessToken";
          break;
        default:
          console.warn("Invalid user type. Redirecting to login.");
          sessionStorage.clear();
          navigate('/');
          return;
      }
    }

    const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      provider.addScope("https://www.googleapis.com/auth/drive");
      provider.addScope("https://www.googleapis.com/auth/drive.file");
      provider.addScope("https://www.googleapis.com/auth/documents");
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
  
      if (currentUser?.email === result?.user?.email) {

        const newIdToken = await result.user.getIdToken(); 
        sessionStorage.setItem("idToken", newIdToken);
  
        const tokenResult = await currentUser.getIdTokenResult();
        const newExpirationTime = new Date(tokenResult.expirationTime).toISOString();
        sessionStorage.setItem(tokenExpirationKey, newExpirationTime);
        sessionStorage.setItem(at, token);
  
        toast({
          title: "GenI",
          description: "Token refreshed successfully.",
          status: "success",
          duration: 2000, 
          isClosable: true,
        });

      } else {
        throw new Error("Different email used. Please use the same email to login.");
      }
    } catch (error) {
      setModalOpen(false);
      console.error("Error refreshing token:", error);
      toast({
        title: "Error refreshing token",
        description: error.message || "Please try again.",
        status: "error",
        duration: 2000, 
        isClosable: true,
      });
      setTimeout(() => {
        sessionStorage.clear();
        navigate('/');
      }, 2000);
    }finally {
      setModalOpen(false);
    } 
  };
  
  
  
  const handleLogout = async () => {
    try {
      await signOut(auth);
      sessionStorage.clear();
      setModalOpen(false);
      navigate('/')
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  
  useEffect(() => {
    const checkSessionExpiry = async () => {
      const userType = sessionStorage.getItem("userType");
      let tokenExpirationKey = null;
      if(userType){
      switch (userType) {
        case "teacher":
          tokenExpirationKey = "TokenExpirationTime";
          break;
        case "student":
          tokenExpirationKey = "studentTokenExpirationTime";
          break;
        case "admin":
          tokenExpirationKey = "AdminTokenExpirationTime";
          break;
        default:
          console.warn("Invalid user type. Redirecting to login.");
          sessionStorage.clear();
          navigate('/');
          return;
      }
    }
  
      const expirationTimeString = sessionStorage.getItem(tokenExpirationKey);
      const expirationTime = expirationTimeString ? new Date(expirationTimeString) : null;
      const currentTime = new Date();
  
      if (expirationTime != null && currentTime >= expirationTime) {
        await signOut(auth);
        sessionStorage.clear();
        navigate('/');
        return;
      }
  
      const warningTime = new Date(expirationTime - 2 * 60 * 1000);
      if (
        currentTime >= warningTime &&
        location.pathname !== '/' &&
        !location.pathname.includes('/login') &&
        expirationTime != null
      ) {
        setModalOpen(true);
      }
    };
  
    checkSessionExpiry();
    const timer = setInterval(checkSessionExpiry, 10000);
  
    return () => clearInterval(timer); 
  }, [location, navigate]);
  
  
  const noHeaderSideNavRoutes = [
    "/teacher-portal/login",
    "/admin-portal/login",
    "/accessdenied",
    "/processing",
    "/student-portal/login",
    "/",
    "/privacypolicy",
  ];

  const hideHeaderAndSideNav = noHeaderSideNavRoutes.includes(location.pathname);

  
  return (
    <div>
      <StudentAuthProvider>
        <AuthProvider>
          <Routes>
          <Route path="/teacher-portal/login" element={<TLogin />} />
            <Route path="/admin-portal/login" element={<ALogin />} />
            <Route path="/accessdenied" element={<Adenied />} />
            <Route path="/processing" element={<Processing />} />
            <Route path="/student-portal/login" element={<SLogin />} /> 
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route
              path="/admin-portal/home"
              element={<AdminProtectedRoute element={<Dashboard />} />}
            />
            <Route
              path="/admin-portal/teacherrequests"
              element={<AdminProtectedRoute element={<TeacherRequests />} />}
            />
            <Route
              path="/admin-portal/teachers"
              element={<AdminProtectedRoute element={<Teachers />} />}
            />
            <Route
              path="/admin-portal/students"
              element={<AdminProtectedRoute element={<Students />} />}
            />
            <Route
              path="/admin-portal/classes"
              element={<AdminProtectedRoute element={<Classes />} />}
            />
          </Routes>
        {!hideHeaderAndSideNav && (
        <Flex>
          <SideNav />
          <Flex
            direction="column"
            ml="280px"
            w="calc(100% - 280px)"
            minH="100vh"
          >
            <MobileNav />
            <Flex
              as="main"
              p="6"
              mt="64px"
              className="App"
            >
          <Routes>
            
            <Route
              path="/student-portal/dashboard"
              element={<StudentProtectedRoute element={<SDashboard />} />}
            />
            <Route
              path="/teacher-portal/dashboard"
              element={<ProtectedRoute element={<TDashboard />} />}
            />
            <Route
              path="/teacher-portal/:projectId/chat"
              element={<ProtectedRoute element={<Chat />} />}
            />
            <Route
              path="/teacher-portal/homeframe"
              element={<ProtectedRoute element={<HomeFrame1 />} />}
            />
            <Route
              path="/teacher-portal/class/:classId"
              element={<ProtectedRoute element={<ClassView2 />} />}
            />
            <Route
              path="/student-portal/class/:classId"
              element={<StudentProtectedRoute element={<ClassView1 />} />}
            />
            <Route
              path="/student-portal/class/:classId/project/:projectId"
              element={<StudentProtectedRoute element={<ProjectView1 />} />}
            />
            <Route
              path="/teacher-portal/class/:classId/project/:projectId"
              element={<ProtectedRoute element={<ProjectView2 />} />}
            />
            <Route
              path="/teacher-portal/class/:classId/project/:projectId/group/:groupId"
              element={<ProtectedRoute element={<GroupView2 />} />}
            />
            <Route
              path="/student-portal/class/:classId/project/:projectId/group/:groupId"
              element={<StudentProtectedRoute element={<GroupView1 />} />}
            />
            <Route
              path="/teacher-portal/messaging"
              element={<ProtectedRoute element={<Message2 />} />}
            />
            <Route
              path="/student-portal/messaging"
              element={<StudentProtectedRoute element={<Message1 />} />}
            />
            <Route
              path="/student-portal/homeframe"
              element={<StudentProtectedRoute element={<HomeFrame2 />} />}
            />
            <Route
              path="/student-portal/gpthistory"
              element={<StudentProtectedRoute element={<ChatGPTHistory1 />} />}
            />
            <Route
              path="/teacher-portal/gpthistory"
              element={<ProtectedRoute element={<ChatGPTHistory2 />} />}
            />
            <Route
              path="/student-portal/learnq"
              element={<StudentProtectedRoute element={<LearnQ />} />}
            />
            <Route
              path="/teacher-portal/learnq"
              element={<ProtectedRoute element={<LearnQ2 />} />}
            />
            <Route
              path="/teacher-portal/config"
              element={<ProtectedRoute element={<Config />} />}
            />
          </Routes>
          </Flex>
          </Flex>
          </Flex>
        )}
          
        </AuthProvider>
      </StudentAuthProvider>
      <SessionExpiryModal
      isOpen={isModalOpen}
      onRefresh={handleRefreshToken}
      onLogout={handleLogout}
      onClose={() => setModalOpen(false)} 
    />
    </div>
  );
}

export default App;
