import { Spinner, Box } from '@chakra-ui/react';
import React from 'react';

export const LoadingSpinner = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    py={4}
  >
    <Spinner
      thickness="3px"
      speed="0.65s"
      emptyColor="gray.200"
      color="purple.500"
      size="md"
      m={2}
    />
  </Box>
);