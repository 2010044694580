import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Text,
    Spinner,
    VStack,
    UnorderedList,
    ListItem,
} from "@chakra-ui/react";
import html2pdf from 'html2pdf.js';

import { fetchSRSforProject } from '../../../Services/Student/studentService.tsx';
import { useParams } from 'react-router';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';

const SRSHistory = () => {
    const { projectId } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [srsHistory, setSRSHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);

    // Fetch SRS history on component mount
    useEffect(() => {
        const fetchSRSHistoryData = async () => {
            setLoading(true);
            try {
                const response = await fetchSRSforProject(projectId); 
                setSRSHistory(response[0] || []); 
                
            } catch (error) {
                console.error("Error fetching SRS history:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSRSHistoryData();
    }, [projectId]);

    const handleFileClick = (document) => {
        setSelectedDocument(document);
        onOpen();
    };
    const generateSRSPDF = () => {
        const element = document.getElementById("srs_document");
        const options = {
            margin: 0.5,
            filename: 'SRS.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: {
                unit: 'in',
                format: [10, 11],
                orientation: 'portrait'
            }
        };

        html2pdf().from(element).set(options).save();
    };

    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'descending' });


    const sortData = (key) => {
        let direction = 'ascending';

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
    
        const sortedData = [...srsHistory.srsData].sort((a, b) => {
            if (key === 'date') {
                
                return direction === 'ascending'
                    ? new Date(b.updatedAt) - new Date(a.updatedAt)
                    : new Date(a.updatedAt) - new Date(b.updatedAt);  
            }
            if (key === 'name') {
                
                return direction === 'ascending' 
                    ? a.studentId.name.localeCompare(b.studentId.name) 
                    : b.studentId.name.localeCompare(a.studentId.name);
            }
            return 0; 
        });
    
        
        setSRSHistory({ ...srsHistory, srsData: sortedData });
        setSortConfig({ key, direction });
    };
    
    

    return (
        <Flex direction="column" alignItems="center" p={4}>
            
            {loading ? (
                <LoadingSpinner/>
            ) : (
                <Box width={"90%"} p={6} mx="auto" mt={4} borderWidth="1px" borderRadius="lg" boxShadow="lg">
                <Heading fontSize="lg" mb={4}>Your project SRS</Heading>
                    <Table variant="simple" size="md">
                    <Thead>
                        <Tr>
                            <Th onClick={() => sortData('name')} style={{ cursor: 'pointer' }}>
                                Student Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                            </Th>
                            <Th>File</Th>
                            <Th onClick={() => sortData('date')} style={{ cursor: 'pointer' }}>
                                Date {sortConfig.key === 'date' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                            </Th>
                        </Tr>
                    </Thead>
                        <Tbody>
                            {srsHistory?.srsData?.map((entry) => (
                                <Tr key={entry._id}>
                                    <Td>{entry.studentId.name}</Td> {/* Adjust based on your data structure */}
                                    <Td>
                                        <Button variant="link" onClick={() => handleFileClick(entry)} color="purple.900">
                                            View Document
                                        </Button>
                                    </Td>
                                    <Td>{new Date(entry.updatedAt).toLocaleString()}</Td> {/* Adjust for your date structure */}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            )}

            {/* Modal for displaying selected document details */}
<Modal isOpen={isOpen} onClose={onClose} size="lg">
    <ModalOverlay />
    <ModalContent maxWidth="800px"> {/* Adjusted size for modal */}
        <ModalHeader>Document Details</ModalHeader>
        <ModalBody overflowY="auto" maxH="500px">
            <VStack spacing={4} align="start">
                {selectedDocument && selectedDocument.srsData.map((srs, index) => (
                    <Box id='srs_document' key={index} p={4} borderWidth={1} borderRadius="md">
                        <Heading mb={4} size="md">Functional and Non-Functional Requirements</Heading>
                        <Heading mb={4} size="sm">1a. Functional Requirements</Heading>
                        
                        {/* Iterate over Functional Requirements */}
                        {srs["1 Functional and Non-Functional Requirements"]["1a"][" Functional Requirements"]["Requirements"].map((req, idx) => (
                            <Box mb={2} key={idx} borderWidth="1px" borderRadius="md" p={4}>
                                <Heading mb={2} size="sm">{req["Requirement Shell"]["Requirement #"]}</Heading>
                                <Text mb={2}>{req["Requirement Shell"]["Description"]}</Text>
                                <Text mb={2} fontWeight="bold">Rationale:</Text>
                                <Text mb={2}>{req["Requirement Shell"]["Rationale"]}</Text>
                                <Text mb={2} fontWeight="bold">Fit Criterion:</Text>
                                <UnorderedList>
                                {req["Requirement Shell"]["Fit Criterion"].map((criterion, idx) => (
                                <ListItem mb={2} key={idx}>{criterion}</ListItem>
                                ))}
                            </UnorderedList>
                                <Text mb={2} fontWeight="bold">Preconditions:</Text> 
                                <Text mb={2}>{req["Requirement Shell"]["Preconditions"]}</Text>
                                <Text mb={2} fontWeight="bold">Postconditions:</Text> 
                                <Text mb={2}>{req["Requirement Shell"]["Postconditions"]}</Text>
                                <Text mb={2} fontWeight="bold">Dependencies:</Text> 
                                <Text mb={2}>{req["Requirement Shell"]["Dependencies"]}</Text>
                                <Text mb={2} fontWeight="bold">Supporting Materials</Text> 
                                <Text mb={2}>{req["Requirement Shell"]["Supporting Materials"]}</Text>
                            </Box>
                        ))}
                        <>
                        {selectedDocument.isNonFunctionalRequired && (
                            <>
                                <Heading mb={3} mt={3} size="sm">1b. Non-Functional Requirements</Heading>
                                
                                {srs["1 Functional and Non-Functional Requirements"]["1b"][" Non-Functional Requirements"].map((req, idx) => (
                                    <Box mb={2} key={idx} borderWidth="1px" borderRadius="md" p={4}>
                                        <Text mb={2}>{req.Description}</Text>
                                        <Text mb={2} fontWeight="bold">Rationale: {req.Rationale}</Text>
                                    </Box>
                                ))}
                            </>
                        )}
                    </>

                    </Box>
                ))}
            </VStack>
        </ModalBody>
        <ModalFooter>
            <Button mr={4} onClick={onClose}>
                Close
            </Button>
            <Button bg={'purple.900'} color={'white'} _hover={{ bg: 'purple.900' }} onClick={generateSRSPDF}>
                Download
            </Button>
        </ModalFooter>
    </ModalContent>
</Modal>
        </Flex>
    );
};

export default SRSHistory;
