import React from 'react';
import { Box, Image, Text, VStack } from '@chakra-ui/react';

const ClassCard = ({ classItem, index, onClick }) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      cursor="pointer"
      transform="scale(0.94)" 
      transition="transform 0.2s ease" 
      _hover={{ transform: "scale(1)" }} 
      onClick={() => onClick(classItem, index)}
      mb={3}
    >
      <Image src={classItem.url} alt={classItem.classname} />
      <VStack p={4} align="start">
        <Text fontWeight="semibold" fontSize="xl">{classItem.classname}</Text>
        <Text>Grade Level: {classItem.gradelevel}</Text>
        <Text>Academic Term: {classItem.term}</Text>
      </VStack>
    </Box>
  );
};

export default ClassCard;
