import { Box, Text, Collapse, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import React from 'react';

const MotionBox = motion(Box);

interface SidebarSectionProps {
  title: string;
  children: ReactNode;
  isOpen: boolean;
  onToggle: () => void;
  maxHeight?: string;
  isCollapsible?: boolean;
  delay?: number;
}

export const SidebarSection = ({
  title,
  children,
  isOpen,
  onToggle,
  maxHeight = "300px",
  isCollapsible = false,
  delay = 0
}: SidebarSectionProps) => {
  const content = (
    <Box
      maxH={maxHeight}
      overflowY="auto"
      sx={{
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'gray.300',
          borderRadius: '18px',
        },
      }}
      css={{
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
    }}
    >
      {children}
    </Box>
  );

  return (
    <MotionBox 
      py="2"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay }}
    >
      <Flex
        px="4"
        py="2"
        align="center"
        cursor={isCollapsible ? "pointer" : "default"}
        onClick={isCollapsible ? onToggle : undefined}
        _hover={isCollapsible ? { bg: 'gray.100' } : undefined}
        borderRadius="md"
      >
        <Text 
          fontSize="xs" 
          color="gray.500" 
          fontWeight="medium"
          letterSpacing="wider"
        >
          {title}
        </Text>
      </Flex>
      {isCollapsible ? (
        <Collapse 
          in={isOpen} 
          animateOpacity 
          style={{ overflow: 'hidden' }}
        >
          {content}
        </Collapse>
      ) : content}
    </MotionBox>
  );
};