import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Flex,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Icon,
  Text,
  Badge,
  Spinner,
  Alert,
  AlertIcon,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  CheckboxGroup,
  useDisclosure,
  Textarea,
} from "@chakra-ui/react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { FiChevronLeft, FiChevronRight, FiDownload, FiFilter } from "react-icons/fi";
import { useAsyncDebounce } from "react-table";
import { getProjectsByUserId, getStudentsByIds, getTeachersByIds } from "../../../Services/Teacher/teacherService.tsx";
import { DatePicker } from 'antd';
import { LoadingSpinner } from "../../../commons/LoadingSpinner.tsx";


const ProjectMessages = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [students, setStudents] = useState({});
  const [teachers, setTeachers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sortColumn, setSortColumn] = useState('sentTime');
  const [sortDirection, setSortDirection] = useState('desc');
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedUsers, setSelectedUsers] = useState([]);
const { isOpen, onOpen, onClose } = useDisclosure();
const [isChecked, setIsChecked] = useState(false);
const [selectedMessage, setSelectedMessage] = useState(null);


  const inputRef = useRef();
  
  const userType = sessionStorage.getItem("userType");
  const CurrentLoggedInTId = sessionStorage.getItem('CurrentLoggedInTId');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const projectsData = await getProjectsByUserId(CurrentLoggedInTId, "teacher");
        setProjects(projectsData);

        const allStudentIds = new Set();
        const allTeacherIds = new Set();

        projectsData.forEach(pjt => {
          pjt.studentIds.forEach(id => allStudentIds.add(id));
          pjt.teacherIds.forEach(id => allTeacherIds.add(id));
        });

        const [studentsData, teachersData] = await Promise.all([
          getStudentsByIds(Array.from(allStudentIds)),
          getTeachersByIds(Array.from(allTeacherIds))
        ]);

        setStudents(studentsData.reduce((acc, student) => {
          acc[student._id] = { name: student.name, email: student.email, photoURL: student.photoURL, type: "student" };
          return acc;
        }, {}));

        setTeachers(teachersData.reduce((acc, teacher) => {
          acc[teacher._id] = { name: teacher.name, email: teacher.email, photoURL: teacher.photoURL, type: "teacher" };
          return acc;
        }, {}));
      } catch (error) {
        console.log(error);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [CurrentLoggedInTId]);



  useEffect(() => {
    if (selectedProject) {
      if(isChecked){
        setMessages(selectedProject.messages || []);
      setFilteredMessages(selectedProject.messages || []);
      }
      else{
      setMessages(selectedProject.individualmessages || []);
      setFilteredMessages(selectedProject.individualmessages || []);
      }
    }
  }, [selectedProject, isChecked]);

  const handleProjectChange = (event) => {
    const projectId = event.target.value;
    const project = projects.find(pjt => pjt._id === projectId);
    setSelectedProject(project);
  };

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const [startDate, endDate] = dateRange;
      const filtered = messages.filter(msg => {
        const sentTime = new Date(msg.sentTime);
        return sentTime >= startDate && sentTime <= endDate;
      });
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages(messages);
    }
  }, [dateRange, messages]);
  

  const handleSearch = useAsyncDebounce((query) => {
    setSearchQuery(query);
    if (query) {
      const lowerQuery = query.toLowerCase();
      const filtered = messages.filter(msg => 
        msg.message.toLowerCase().includes(lowerQuery) ||
        (students[msg.sender]?.name.toLowerCase().includes(lowerQuery)) ||
        (teachers[msg.sender]?.name.toLowerCase().includes(lowerQuery)) ||
        (students[msg.receiver]?.name.toLowerCase().includes(lowerQuery)) ||
        (teachers[msg.receiver]?.name.toLowerCase().includes(lowerQuery))
      );
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages(messages);
    }
  });

  const sortMessages = (messages, column, direction) => {
    return [...messages].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const sortedMessages = useMemo(() => {
    return sortMessages(filteredMessages, sortColumn, sortDirection);
  }, [filteredMessages, sortColumn, sortDirection]);

  const paginatedMessages = sortedMessages.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  const handleSort = (column) => {
    setSortDirection((prevDirection) => (sortColumn === column && prevDirection === 'asc' ? 'desc' : 'asc'));
    setSortColumn(column);
  };


  const exportToCSV = () => {
    if (!filteredMessages.length) return;
    let headers;
    if(!isChecked){
     headers = ["Index", "Sender","Sender Email", "Receiver","Receiver Email", "Message", "Time"];
    }else{
      headers = ["Index", "Sender","Sender Email", "Message", "Time"];
    }

    const rows = filteredMessages.map((msg, index) => {
      const sender = students[msg.sender] || teachers[msg.sender];
      const receiver = students[msg.receiver] || teachers[msg.receiver];
      if(!isChecked){
      return [
        String(index + 1 + pageIndex * pageSize),
        String(sender?.name || "Unknown"),
        String(sender?.email || "Unknown"),
        String(receiver?.name || "Unknown"),
        String(receiver?.email || "Unknown"),
        String(msg.message),
        new Date(msg.sentTime).toLocaleString()
      ];
    }
    else{
      return [
        String(index + 1 + pageIndex * pageSize),
        String(sender?.name || "Unknown"),
        String(sender?.email || "Unknown"),
        String(msg.message),
        new Date(msg.sentTime).toLocaleString()
      ];
    }
    });
  
    const csvContent = [
      headers.join(","),
      ...rows.map(row => row.map(item => `"${String(item).replace(/"/g, '""')}"`).join(","))
    ].join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${selectedProject.projectName}-messages.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFilterClick = () => {
    onOpen();
  };
  const handleFilterSubmit = () => {
    const filtered = messages.filter(msg =>
      selectedUsers.includes(msg.sender) || selectedUsers.includes(msg.receiver)
    );
    setFilteredMessages(filtered);
    onClose();
  };

  const handleClearFilters = () => {
    setFilteredMessages(messages);
    onClose();
  } 

  const availableUsers = useMemo(() => {
    const allUsers = [
      ...Object.entries(students),
      ...Object.entries(teachers)
    ].filter(([id]) => id !== CurrentLoggedInTId);
    return allUsers.map(([id, user]) => ({
      id,
      name: user.name,
      email: user.email,
      type: user.type,
    }));
  }, [students, teachers, CurrentLoggedInTId]);
    

  
  const handleCheckboxChange = () => {
    setIsChecked(prev => !prev); 
  };

  return (
    <Box  height={200}>
      {loading ? (
        <LoadingSpinner/>
      ) : error ? (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      ) : (
        <>
          <Flex justify="right" mb="15px">
            {selectedProject && (
              <Checkbox pr={475} isChecked={isChecked} onChange={handleCheckboxChange}>
              Group Conversation?
            </Checkbox>
            )}
          {selectedProject && (
  <Button  variant="ghost" onClick={exportToCSV} mr={4}>
    <Box as={FiDownload}  /> 
  </Button>
)}
{selectedProject && (
  <Button mr={4} variant="ghost" onClick={handleFilterClick} >
    <Box  as={FiFilter}  /> 
  </Button>
)}
            <Select
              width="30%"
              placeholder="Select Project"
              onChange={handleProjectChange}
              cursor="pointer"
              value={selectedProject ? selectedProject._id : ""}
              size={'md'}
            >
              {projects.map((pjt) => (
                <option key={pjt._id} value={pjt._id}>
                  {pjt.projectName}
                </option>
              ))}
            </Select>
          </Flex>

          {selectedProject && (
            <>
            <Flex mb="20px" align="center">
      <Box mr="20px" flex="1">
        <DatePicker.RangePicker
          onChange={(dates) => setDateRange(dates ? [dates[0].toDate(), dates[1].toDate()] : [null, null])}
          format="YYYY-MM-DD"
          style={{ width: '100%' }}
        />
      </Box>
      <Box flex="2">
        <Box boxShadow="0 0 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1)" borderRadius="xl">
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
            <Input
              ref={inputRef}
              type="text"
              value={searchQuery || ""}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search..."
            />
            {searchQuery && (
              <InputRightElement
                cursor="pointer"
                children={<CloseIcon fontSize={14} _hover={{ color: "gray.600" }} color="gray.300" />}
                onClick={() => {
                  setSearchQuery("");
                  handleSearch("");
                }}
              />
            )}
          </InputGroup>
        </Box>
      </Box>
    </Flex>

              <Box 
                bg="white" 
                borderRadius="xl" 
                boxShadow="0 0 2px rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.1)"
                p="4"
                ml={-4}
              >
                <TableContainer>
                  <Table variant="simple" size="md">
                    <Thead>
                      <Tr>
                        <Th cursor="pointer" onClick={() => handleSort('index')}>Index</Th>
                        <Th cursor="pointer" onClick={() => handleSort('receiver')}>Sender</Th>
                        {!isChecked && (<Th cursor="pointer" onClick={() => handleSort('sender')}>Receiver</Th>)}
                        <Th cursor="pointer" onClick={() => handleSort('message')}>Message</Th>
                        <Th cursor="pointer" onClick={() => handleSort('sentTime')}>Time</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {paginatedMessages.map((msg, index) => {
                        const sender = students[msg.sender] || teachers[msg.sender];
                        const receiver = students[msg.receiver] || teachers[msg.receiver];
                        const senderType = sender?.type || 'unknown';
                        const receiverType = receiver?.type || 'unknown';

                        return (
                          <Tr key={msg._id}>
                            <Td>{index + 1 + pageIndex * pageSize}</Td>
                            <Td>
                              <Flex align="center">
                                <Avatar src={sender?.photoURL} name={sender?.name} size="sm" />
                                <Box ml="10px">
                                  <Text fontWeight="bold">{sender?.name || 'Unknown'}
                                    <Badge
                                      borderRadius="xl"
                                      ml="10px"
                                      px={2}
                                      py={0.5}
                                      fontSize="0.6em"
                                      bg={senderType === 'teacher' ? 'purple.700' : 'green'}
                                      color={senderType === 'teacher' ? 'white' : 'white'}
                                    >
                                      {senderType.charAt(0).toLowerCase() + senderType.slice(1)}
                                    </Badge>
                                  </Text>
                                  <Text textColor={'#888888'} fontSize="sm">{sender?.email || 'Unknown'}</Text>
                                </Box>
                              </Flex>
                            </Td>
                            {!isChecked &&(
                            <Td>
                              <Flex align="center">
                                <Avatar src={receiver?.photoURL} name={receiver?.name} size="sm" />
                                <Box ml="10px">
                                  <Text fontWeight="bold">{receiver?.name || 'Unknown'}
                                    <Badge
                                      borderRadius="xl"
                                      ml="10px"
                                      px={2}
                                      py={0.5}
                                      fontSize="0.6em"
                                      bg={receiverType === 'teacher' ? 'purple.700' : 'green'}
                                      color={receiverType === 'teacher' ? 'white' : 'white'}
                                    >
                                      {receiverType.charAt(0).toLowerCase() + receiverType.slice(1)}
                                    </Badge>
                                  </Text>
                                  <Text textColor={'#888888'} fontSize="sm">{receiver?.email || 'Unknown'}</Text>
                                </Box>
                              </Flex>
                            </Td>
                            )}
                            <Td>
                            <Flex
                            align="center"
                            direction="column"
                            onClick={() => setSelectedMessage(msg)}
                            cursor="pointer"
                            >
                                {msg.message.length > 50 ? (
                                  <Textarea
                                    value={msg.message}
                                    readOnly
                                    resize="none"
                                    width="300px"
                                    css={{ whiteSpace: 'pre-wrap' }}
                                  />
                                ) : (
                                  <Text
                                    width="300px"
                                    css={{ whiteSpace: 'pre-wrap' }}
                                  >
                                    {msg.message}
                                  </Text>
                                )}
                              </Flex>
                            </Td>
                            <Td>{new Date(msg.sentTime).toLocaleString()}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>

                <Flex height={2} mt="4" align="center" justify="flex-end">
                  <Button
                    onClick={() => setPageIndex((old) => Math.max(old - 1, 0))}
                    disabled={pageIndex === 0}
                    leftIcon={<FiChevronLeft />}
                    variant={'ghost'}
                  >
                  </Button>
                  <Button
                    onClick={() => setPageIndex((old) => Math.min(old + 1, Math.ceil(sortedMessages.length / pageSize) - 1))}
                    disabled={pageIndex >= Math.ceil(sortedMessages.length / pageSize) - 1}
                    rightIcon={<FiChevronRight />}
                    variant={'ghost'}
                  >
                    
                  </Button>
                </Flex>
              </Box>
            </>
          )}
        </>
      )}

<Modal isOpen={isOpen} onClose={onClose}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Filter Messages by User</ModalHeader>
    <ModalCloseButton />
    <ModalBody maxHeight="300px" overflowY="auto">
      <CheckboxGroup value={selectedUsers} onChange={setSelectedUsers}>
        <Flex direction="column">
          {availableUsers.map(user => (
            <Checkbox key={user.id} value={user.id}>
              {user.name} ({user.type})
            </Checkbox>
          ))}
        </Flex>
      </CheckboxGroup>
    </ModalBody>
    <ModalFooter>
      
      
      <Button
  mr={3}
  onClick={handleClearFilters}
>
  Clear Filters
</Button>
<Button
      mr={4}
        bg={'purple.900'}
        _hover={{ bg: 'purple.800', color: 'white' }}
        color={'white'}
        onClick={handleFilterSubmit}
      >
        Apply Filter
      </Button>

    </ModalFooter>
  </ModalContent>
</Modal>

{selectedMessage && (
  <Modal isOpen={!!selectedMessage} onClose={() => setSelectedMessage(null)} isCentered>
    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(2px)" />
    <ModalContent maxWidth="600px" maxHeight="500px">
      <ModalCloseButton onClick={() => setSelectedMessage(null)} />
      <Box p="6" borderRadius="md" overflowY="auto" maxHeight="400px">
        <Text fontSize="lg" fontWeight="bold" mb="4">
          Message 
        </Text>
        <Text css={{ whiteSpace: 'pre-wrap' }}>
          {selectedMessage.message}
        </Text>
      </Box>
    </ModalContent>
  </Modal>
)}
    </Box>
  );
};

export default ProjectMessages;
