import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
  Spinner,
  HStack,
  useToast,
} from '@chakra-ui/react';
import ADashboard from '../AdminDashboard/ADashboard.tsx';
import { getTeachersByIds } from '../../../Services/Teacher/teacherService.tsx';
import { changeTeacherStatus, getAdminById } from '../../../Services/Admin/AdminService.tsx';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';

function TeacherRequests() {
  const [teacherRequests, setTeacherRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [aloading, setALoading] = useState(false);
  const [rloading, setRLoading] = useState(false);
  const [admin, setAdmin] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const fetchTeacherRequests = async () => {
      try {
        const adminId = sessionStorage.getItem('CurrentLoggedInAId');
        const admin = await getAdminById(adminId?.toString());
        setAdmin(admin);
        if (admin?.requests?.length > 0) {
          const teacherIds = admin.requests.map((req) => req.teacherId);
          const teachers = await getTeachersByIds(teacherIds);
          setTeacherRequests(teachers);
        }
      } catch (error) {
        console.error('Error fetching teacher requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeacherRequests();
  }, []);

  const handleStatusChange = async (teacherId, action) => {
    if(action == "approve")
    setALoading(true);
    else if(action == "reject")
    setRLoading(true);
    try {
      await changeTeacherStatus({
        adminId: admin._id,
        teacherId,
        action,
      });

      
      setTeacherRequests((prev) =>
        prev.filter((teacher) => teacher._id !== teacherId)
      );

      toast({
        title: `Teacher ${action === 'approve' ? 'approved' : 'rejected'}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Failed to update status',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    finally{
        setALoading(false);
        setRLoading(false);
    }
  };

  return (
    <ADashboard>
      <Box mt={24} ml={6} mr={6}>
        <Text fontSize="2xl" fontWeight="bold" mb={8}>
          Teacher Account Requests
        </Text>
        <Box bg="white" rounded="lg" shadow="sm" borderWidth="1px">
          {loading ? (
            <HStack justify="center" py={10}>
              <LoadingSpinner />
            </HStack>
          ) : teacherRequests.length === 0 ? (
            <Box p={8} textAlign="center">
              <Text fontSize="lg" color="gray.500">
                No pending requests.
              </Text>
            </Box>
          ) : (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>School Name</Th>
                  <Th>School Email</Th>
                  <Th>Subject</Th>
                  <Th>Request Date</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {teacherRequests.map((teacher) => (
                  <Tr key={teacher._id}>
                    <Td position="relative">
                      <Box
                        position="relative"
                        _hover={{
                          '& .teacherInfo': {
                            opacity: 1,
                            visibility: 'visible',
                          },
                        }}
                      >
                        {teacher.firstName} {teacher.lastName}
                        <Box
                          className="teacherInfo"
                          position="absolute"
                          top="220%"
                          left="81%"
                          transform="translateX(-50%)"
                          bg="white"
                          p={6}
                          borderRadius="md"
                          boxShadow="lg"
                          minWidth="350px"
                          zIndex={10}
                          sx={{ textAlign: 'left' }}
                          opacity={0}
                          visibility="hidden"
                          transition="opacity 1.1s ease, visibility 1.1s ease"
                        >
                          <Text fontWeight="bold" mb={2}>
                            Details:
                          </Text>
                          <Text mb={1}><b>Name:</b> {teacher.firstName} {teacher.lastName}</Text>
                          <Text mb={1}><b>Email:</b> {teacher.email}</Text>
                          <Text mb={1}><b>Phone:</b> {teacher.phone}</Text>
                          <Text mb={1}><b>School:</b> {teacher.school}</Text>
                          <Text mb={1}><b>School email:</b> {teacher.schoolmail}</Text>
                          <Text><b>Subject area:</b> {teacher.subjectArea}</Text>
                        </Box>
                      </Box>
                    </Td>
                    <Td>{teacher.school}</Td>
                    <Td>{teacher.schoolmail}</Td>
                    <Td>{teacher.subjectArea}</Td>
                    <Td>{new Date(teacher.createdAt).toLocaleDateString()}</Td>
                    <Td>
                    <HStack spacing={2}>
                        
                        <Button
                        size="sm"
                        colorScheme="purple"
                        onClick={() => handleStatusChange(teacher._id, 'approve')}
                        isLoading={aloading}  
                        isDisabled={aloading || rloading}  
                        >
                        Approve
                        </Button>
                        
                        <Button
                        size="sm"
                        colorScheme="red"
                        variant="outline"
                        onClick={() => handleStatusChange(teacher._id, 'reject')}
                        isLoading={rloading}  
                        isDisabled={aloading || rloading} 
                        >
                        Reject
                        </Button>
                    </HStack>
                    </Td>

                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Box>
      </Box>
    </ADashboard>
  );
}

export default TeacherRequests;
