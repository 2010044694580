import { useEffect, useState } from 'react';
import {
    Box, Button, Flex, Heading, IconButton, Text, Textarea,
    VStack, Spinner, HStack, Tooltip, Modal, ModalOverlay,
    ModalContent, ModalHeader, ModalFooter, ModalBody, useToast, Checkbox, ListItem, UnorderedList,
} from "@chakra-ui/react";
import { ArrowBackIcon, QuestionIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import { getSRSFromGPT } from '../../../Services/ChatGPT/Student/ChatGPTService.tsx';
import { fetchSRSforProject, postSRSData } from '../../../Services/Student/studentService.tsx';
import DummyChatBox from './GPTbox.jsx';
import html2pdf from 'html2pdf.js';
import { getProjectsByIds } from '../../../Services/Teacher/teacherService.tsx';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';

const SRS = () => {
    const { projectId } = useParams(); // Get projectId from URL params
    const [srsLoading, setSRSLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [projectBackLog, setProjectBackLog] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [srsData, setSRSData] = useState({});
    const currentLoggedInSId = sessionStorage.getItem("CurrentLoggedInSId").trim();
    const [available, setAvailable] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // State for confirmation modal
    const [response, setResponse] = useState([]);
    const [isNonFunctionalRequired, setIsNonFunctionalRequired] = useState(false);
    
    const toast = useToast(); // Initialize toast for notifications
    const [currentTeacherId,setCurrentTeacherId] = useState("");

    // Fetch SRS data on component mount
    useEffect(() => {
        const fetchSRSData = async () => {
            try {
                const response = await fetchSRSforProject(projectId); // Fetch SRS data
                if (response) {
                    setResponse(response);
                    setProjectDescription(response[0].projectDescription); // Set project description from response
                }
                const data = await getProjectsByIds([projectId]);
                setCurrentTeacherId(data[0].teacherIds[0]);
            } catch (error) {
                console.error("Error fetching SRS data:", error);
            }
        };

        fetchSRSData();
    }, [projectId]);

    const handleSRSsubmit = async () => {
        
        setSRSLoading(true);
        try {
            const response = await getSRSFromGPT(projectDescription, projectBackLog, isNonFunctionalRequired, currentTeacherId);
            
            let trimmedResponse = response;

            if (response.startsWith("```")) {
                trimmedResponse = response.replace(/^```json|```$/g, "");
            }
            trimmedResponse = JSON.parse(trimmedResponse);
            console.log(trimmedResponse);
            setSRSData(trimmedResponse);
        } catch (error) {
            console.error("Error reading the file:", error);
        } finally {
            setAvailable(true);
            setSRSLoading(false);
        }
    }

    const handleSRSBack = () => {
        setAvailable(false);
    }

    const [isChatOpen, setIsChatOpen] = useState(false);
    const handleOpenChat = () => {
        setIsChatOpen(true);
    };

    const handleCloseChat = () => {
        setIsChatOpen(false);
    };

    const generateSRSPDF = () => {
        const element = document.getElementById("srs_document");
        const options = {
            margin: 0.5,
            filename: 'SRS.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: {
                unit: 'in',
                format: [10, 11],
                orientation: 'portrait'
            }
        };

        html2pdf().from(element).set(options).save();
    };

    const handleSaveClick = () => {
        setIsConfirmationOpen(true); // Open confirmation modal
    };

    const saveSRStoDB = async () => {
        setSaveLoading(true);
        try {
            await postSRSData(projectId, available ? projectDescription : undefined, srsData, currentLoggedInSId, isNonFunctionalRequired);
            
            // Show success toast notification
            toast({
                title: "Success!",
                description: "SRS data saved successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error saving SRS data:", error);
            // Show error toast notification
            toast({
                title: "Error!",
                description: "Failed to save SRS data.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setSaveLoading(false);
            setIsConfirmationOpen(false); // Close confirmation modal
            sessionStorage.setItem("activeTabInStudentProject", 5); // Set session storage
            window.location.reload(); // Refresh the page
        }
    };

    const handleProjectBackLogChange = (e) => {
        setProjectBackLog(e.target.value);
      };
      

    return (
        <Flex justifyContent="center" alignItems="center">
            <Box mt={6} w={"90%"} h="auto" p={4} ml={-4} borderRadius="xl" boxShadow="0 0 10px rgba(0, 0, 0, 0.1)" position="relative">
                {saveLoading && (
                    <Box position="absolute" top={0} left={0} right={0} bottom={0} bg="rgba(255, 255, 255, 0.8)" display="flex" justifyContent="center" alignItems="center" zIndex={1}>
                        <LoadingSpinner/>
                    </Box>
                )}
                <VStack spacing={4}>
                    <Heading size={'lg'} mb={2} mt={-2} color={"purple.900"} textAlign="center">
                        SRS
                    </Heading>
                </VStack>
                {available ? (
                    <>
                        <Flex justifyContent="space-between" alignItems="center" mb={8}>
                            <IconButton
                                aria-label="Go back"
                                icon={<ArrowBackIcon />}
                                onClick={handleSRSBack}
                                bg={'purple.900'}
                                color={'white'}
                                _hover={{ bg: 'purple.900' }}
                                size="sm"
                            />
                            <Box>
                                <Tooltip label="Ask AI" hasArrow>
                                    <IconButton
                                        aria-label="Ask AI"
                                        icon={<QuestionIcon />}
                                        onClick={handleOpenChat}
                                        color={'purple.900'}
                                        size="lg"
                                        variant={'ghost'}
                                        mr={4}
                                    />
                                </Tooltip>
                                <Button onClick={generateSRSPDF} bg={'purple.900'} color={'white'} _hover={{ bg: 'purple.900' }}>
                                    Generate PDF
                                </Button>
                                <Button ml={4} onClick={handleSaveClick} bg={'purple.900'} color={'white'} _hover={{ bg: 'purple.900' }}>
                                    Save
                                </Button>
                            </Box>
                        </Flex>

                        {isChatOpen && (
                            <DummyChatBox onClose={handleCloseChat} srsData={srsData} setSRSData={setSRSData} isNonFunctionalRequired={isNonFunctionalRequired} projectBackLog={projectBackLog} projectDescription={projectDescription} currentTeacherId={currentTeacherId} />
                        )}

                        <Box id='srs_document' p={4} borderWidth={4}>
                            <VStack spacing={4} align="start">
                                <Heading size="lg">Functional and Non-Functional Requirements</Heading>
                                <Heading size="md">1a. Functional Requirements</Heading>
                                { 
  srsData["1 Functional and Non-Functional Requirements"]["1a. Functional Requirements"]["Requirements"].map((req, index) => (
    <Box key={index} borderWidth="1px" borderRadius="md" p={4}>
      {/* Requirement Number */}
      <Heading size="sm">{req["Requirement Shell"]["Requirement #"]}</Heading>

      {/* Description */}
      <Text>{req["Requirement Shell"]["Description"]}</Text>
      
      {/* Rationale */}
      <Text fontWeight="bold">Rationale:</Text>
      <Text>{req["Requirement Shell"]["Rationale"]}</Text>
      
      {/* Fit Criterion as Unordered List */}
      <Text fontWeight="bold">Fit Criterion:</Text>
      <UnorderedList>
        {req["Requirement Shell"]["Fit Criterion"].map((criterion, idx) => (
          <ListItem key={idx}>{criterion}</ListItem>
        ))}
      </UnorderedList>
      
      {/* Preconditions */}
      <Text fontWeight="bold">Preconditions:</Text> 
      <Text>{req["Requirement Shell"]["Preconditions"]}</Text>
      
      {/* Postconditions */}
      <Text fontWeight="bold">Postconditions:</Text> 
      <Text>{req["Requirement Shell"]["Postconditions"]}</Text>
      
      {/* Dependencies */}
      <Text fontWeight="bold">Dependencies:</Text>
      <Text>{req["Requirement Shell"]["Dependencies"]}</Text>
      
      {/* Supporting Materials */}
      <Text fontWeight="bold">Supporting Materials:</Text>
      <Text>{req["Requirement Shell"]["Supporting Materials"]}</Text>
      
      {/* History */}
      <Text fontWeight="bold">History:</Text>
      <Text>{req["Requirement Shell"]["History"]}</Text>
    </Box>
  ))
}

                                {isNonFunctionalRequired && (
                                    <>
                                        <Heading size="md">1b. Non-Functional Requirements</Heading>
                                        {srsData["1 Functional and Non-Functional Requirements"]["1b. Non-Functional Requirements"].map((req, index) => (
                                            <Box key={index} borderWidth="1px" borderRadius="md" p={4}>
                                                <Text>{req.Description}</Text>
                                                <Text fontWeight="bold">Rationale: {req.Rationale}</Text>
                                            </Box>
                                        ))}
                                    </>
                                )}

                            </VStack>
                        </Box>
                    </>
                ) : (
                    <>
                    <Flex justifyContent="space-between" alignItems="center" mb={4}>
                        <Heading size="lg"></Heading>
                        <Checkbox
                            isChecked={isNonFunctionalRequired}
                            onChange={(e) => setIsNonFunctionalRequired(e.target.checked)}
                            colorScheme="purple"
                        >
                            Include Non-Functional Requirements
                        </Checkbox>
                    </Flex>
                    <HStack mt={8} mb={4} justifyContent="center" spacing={8}>
                        {!response.length > 0 ? (
                        <Box flex="1">
                            <Textarea
                                placeholder="Enter project description"
                                value={projectDescription}
                                onChange={(e) => setProjectDescription(e.target.value)}
                                height="200px"
                                borderColor="gray.200"
                                _hover={{ borderColor: "gray.400" }}
                                borderRadius={'xl'}
                                borderWidth={2}
                                boxShadow={'md'}
                            />
                        </Box>
                    ) : (
                        <Box flex="1">
                            <Text
                                height="200px"
                                p={4}
                                borderRadius={'xl'}
                                borderWidth={2}
                                boxShadow={'md'}
                                overflowY="auto" // This will handle long text by allowing scrolling
                                borderColor="gray.200"
                                _hover={{ borderColor: "gray.400" }}
                            >
                                {projectDescription || "No project description available."} {/* Fallback if no description */}
                            </Text>
                        </Box>
                    )}


                        <Box flex="1">
                            <Textarea
                                placeholder="Enter user story"
                                value={projectBackLog}
                                onChange={handleProjectBackLogChange}
                                height="200px"
                                borderColor="gray.200"
                                _hover={{ borderColor: "gray.400" }}
                                borderRadius={'xl'}
                                borderWidth={2}
                                boxShadow={'md'}
                            />
                        </Box>
                    </HStack>
                    </>
                )}
        {!available&&(
                <Box display="flex" justifyContent="center" mt={4}>
                    <Button
                        bg={'purple.900'}
                        color={'white'}
                        _hover={{ bg: "purple.800" }}
                        onClick={handleSRSsubmit}
                        mt={16}
                        isDisabled={!projectDescription || !projectBackLog || srsLoading}
                    >
                        {srsLoading ? (
                            <Box display="flex" alignItems="center">
                                <LoadingSpinner/>
                                <Text ml={2}>Loading...</Text>
                            </Box>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                </Box>
        )}
            </Box>

            {/* Confirmation Modal */}
            <Modal isOpen={isConfirmationOpen} onClose={() => setIsConfirmationOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm Save</ModalHeader>
                    <ModalBody>
                        <Text>Are you sure you want to save this final SRS?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button color={'white'} bg={'purple.900'} _hover={{bg:'purple.900'}} onClick={saveSRStoDB}>
                            Yes
                        </Button>
                        <Button onClick={() => setIsConfirmationOpen(false)} ml={3}>
                            No
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default SRS;
