import { Box, Avatar, Text, Flex, IconButton, VStack } from '@chakra-ui/react';
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { X } from 'lucide-react';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

export const UserDetailPage = ({ selectedUser, closeUserDetail }) => (
    <AnimatePresence mode="wait">
    <MotionFlex
      direction="column"
      borderRight="1px"
      borderColor="gray.200"
      transition={{ type: 'spring', stiffness: 300, damping: 25 }} // Same transition settings as the box
    >
      <AnimatePresence mode="wait">
        {selectedUser && (
          <MotionBox
            w="300px"
            h="full"
            bg="white"
            borderRight="1px"
            borderColor="gray.200"
            p={6}
            overflowY="auto"
            boxShadow="lg"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 25 }} // Match Flex transition
            borderRadius="lg"
            key="user-detail"
          >
    <Flex justify="space-between" align="center" mb={6}>
    <Text fontSize="xl" fontWeight="bold" mb={4}>
          Details
        </Text>
      <IconButton
        icon={<X size={20} />}
        aria-label="Close"
        variant="ghost"
        onClick={closeUserDetail}
      />
    </Flex>
    <VStack spacing={6} align="center">
      {/* Profile Picture */}
      <Avatar
        src={selectedUser?.photoURL}
       size={'md'}
        borderRadius="full"
        boxShadow="md"
      />
      {/* User Info */}
      <Box textAlign="center">
        <Text fontSize="2xl" fontWeight="semibold" color="gray.800">{`${selectedUser?.name}`}</Text>
        <Text fontSize="lg" color="gray.600" mt={2}>{selectedUser?.schoolmail}</Text>
        <Text fontSize="sm" color="gray.500" mt={1}>{selectedUser?.email}</Text>
      </Box>
    </VStack>
  </MotionBox>
        )}
  </AnimatePresence>
  </MotionFlex>
  </AnimatePresence>
);
