import { axiosInstance } from '../../axiosUtils.ts';

export const sendUserData = async (userData) => {
  try {
    const data = await axiosInstance.post('/api/teacher', userData)
    return data.data;
  } catch (error) {
    console.error('Error sending user data:', error);
    throw error; 
  }
};

export const createClass = async (data: {
  gradeLevel: string;
  className: string;
  academicYear: string;
  academicSemester: string;
  url: string;
  currentUserId: string;
  schedule: { day: string; startTime: string; endTime: string }[]; 
  token: string;
}) => {
  try {
    const response = await axiosInstance.post('/api/createclass', {
      gradeLevel: data.gradeLevel,
      className: data.className,
      academicYear: data.academicYear,
      academicSemester: data.academicSemester,
      url: data.url,
      currentUserId: data.currentUserId,
      schedule: data.schedule, 
      token: data.token
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'An error occurred while creating the class.');
  }
};


export const updateClassDetails = async (classId, newClassDetails) => {
  try {
    const response = await axiosInstance.put(`/api/updateclass/${classId}`, {
      gradelevel: newClassDetails.gradelevel,
      classname: newClassDetails.classname,
      academicYear: newClassDetails.academicYear,
      academicSemester: newClassDetails.academicSemester,
      schedule: newClassDetails.schedule,
      status : newClassDetails.status,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'An error occurred while updating the class.');
  }
};

export const updateProject = async (projectId, projectData) => {
  const { chatOption, discordServerId, projectName, projectGoal } = projectData;
  
  let updatedData = {
    projectName,
    projectGoal,
    nativeChat: chatOption === 'Native Chat',
    discordServerId: chatOption === 'Discord Server' ? discordServerId : null
  };

  try {
    const response = await axiosInstance.put(`/api/updateproject/${projectId}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating project:', error);
    throw error;
  }
};

export const updateGroup = async (groupId, groupData) => {
  const { chatOption, discordServerId, groupName, groupDescription} = groupData;
  
  let updatedData = {
    groupName,
    groupDescription,
    nativeChat: chatOption === 'Native Chat',
    discordServerId: chatOption === 'Discord Server' ? discordServerId : null
  };

  try {
    const response = await axiosInstance.put(`/api/updategroup/${groupId}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating group:', error);
    throw error;
  }
};



export const getClasses = async() => {
  try{
    const response = await axiosInstance.get('/api/classes');
    return response.data;
  }
  catch(error){
    throw new Error(error.response?.data?.message || 'An error occurred while fetching the classes.');
  }
}

export const getProjects = async() => {
  try{
    const response = await axiosInstance.get('/api/projects');
    return response.data;
  }
  catch(error){
    throw new Error(error.response?.data?.message || 'An error occurred while fetching the projects.');
  }
}


export const getClassById = async (classId: string) => {
  try {
    const response = await axiosInstance.get(`/api/classes/${classId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'An error occurred while fetching the class details.');
  }
};

export const getTeachersByIds = async (ids) => {
  try {
    const response = await axiosInstance.post('/api/teachers', { ids }); // Send IDs in the body
    return response.data;
  } catch (error) {
    console.error('Error fetching teachers:', error);
    throw error;
  }
};

export const getStudentsByIds = async (ids) => {
  try {
    const response = await axiosInstance.post('/api/students', { ids }); // Send IDs in the body
    return response.data;
  } catch (error) {
    console.error('Error fetching teachers:', error);
    throw error;
  }
};

export const getProjectsByIds = async (ids) => {
  try {
    const response = await axiosInstance.post('/api/getprojects', { ids }); // Send IDs in the body
    return response.data;
  } catch (error) {
    console.error('Error fetching teachers:', error);
    throw error;
  }
};




export const getClassesByUserId = async (id, userType) => {
  try {
    let response;
    
    if (userType === 'teacher') {
      response = await axiosInstance.get(`/api/getclassesbyteacher/${id}`);
    } else if (userType === 'student') {
      response = await axiosInstance.get(`/api/getclassesbystudent/${id}`);
    } else {
      throw new Error('Invalid user type');
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching classes:', error);
    throw error;
  }
};

export const getProjectsByUserId = async (id, userType) => {
  try {
    let response;
    
    if (userType === 'teacher') {
      response = await axiosInstance.get(`/api/getprojectsbyteacher/${id}`);
    } else if (userType === 'student') {
      response = await axiosInstance.get(`/api/getprojectsbystudent/${id}`);
    } else {
      throw new Error('Invalid user type');
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching classes:', error);
    throw error;
  }
};

export const createStudents = async (emails) => {
  try {
    const response = await axiosInstance.post(`/api/createstudents`, { emails });
    return response.data.studentIds;
  } catch (error) {
    console.error('Error creating students:', error);
    throw new Error('Failed to create students');
  }
};

export const createTeachers = async (emails) => {
  try {
    const response = await axiosInstance.post(`/api/createteachers`, { emails });
    return response.data.teacherIds;
  } catch (error) {
    console.error('Error creating teachers:', error);
    throw new Error('Failed to create teachers');
  }
};

export const addStudentsToClass = async (classId, studentIds, token) => {
  try {
    await axiosInstance.put(`/api/classes/${classId}/students`, { studentIds, token });
  } catch (error) {
    console.error('Error adding students to class:', error);
    throw new Error('Failed to add students to class');
  }
};

export const addTeachersToClass = async (classId, teacherIds, token) => {
  try {
    await axiosInstance.put(`/api/classes/${classId}/teachers`, { teacherIds, token });
  } catch (error) {
    console.error('Error adding teachers to class:', error);
    throw new Error('Failed to add teachers to class');
  }
};

export const addTeachersToProject = async (projectId, teacherIds) => {
  try {
    await axiosInstance.put(`/api/projects/${projectId}/teachers`, { teacherIds });
  } catch (error) {
    console.error('Error adding teachers to project:', error);
    throw new Error('Failed to add teachers to project');
  }
};

export const addTeachersToGroup = async (groupId, teacherIds) => {
  try {
    
    await axiosInstance.put(`/api/groups/${groupId}/teachers`, { teacherIds });
  } catch (error) {
    console.error('Error adding teachers to group:', error);
    throw new Error('Failed to add teachers to group');
  }
};


export const addStudentsToProject = async (projectId, studentIds) => {
  try {
    await axiosInstance.put(`/api/projects/${projectId}/students`, { studentIds });
  } catch (error) {
    console.error('Error adding students to project:', error);
    throw new Error('Failed to add students to project');
  }
};

export const addStudentsToGroup = async (groupId, studentIds) => {
  try {
    await axiosInstance.put(`/api/groups/${groupId}/students`, { studentIds });
  } catch (error) {
    console.error('Error adding students to group:', error);
    throw new Error('Failed to add students to group');
  }
};

export const addProject = async (formData) => {
  try {
    const response = await axiosInstance.post(`/api/addproject`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data.project;
  } catch (error) {
    console.error('Error adding project to class:', error);
    throw new Error('Failed to add project to class');
  }
};

export const addGroup = async (data) => {
  try{
    const response = await axiosInstance.post('/api/addgroup',data);
    return response.data.group;
  }
  catch (error) {
    console.error('Error adding group to project:', error);
    throw new Error('Failed to add group to project');
  }
}


export const addAnnouncementToClass = async (classId, announcementData) => {
  try {
    const response = await axiosInstance.post(`/api/classes/${classId}/announcements`, announcementData);
    return response.data; 
  } catch (error) {
    console.error('Error adding announcement to class:', error);
    throw new Error('Failed to add announcement to class');
  }
};

export const addAnnouncementToProject = async (projectId, announcementData) => {
  try {
    const response = await axiosInstance.post(`/api/projects/${projectId}/announcements`, announcementData);
    return response.data; 
  } catch (error) {
    console.error('Error adding announcement to project:', error);
    throw new Error('Failed to add announcement to project');
  }
};

export const addAnnouncementToGroup = async (groupId, announcementData) => {
  try {
    const response = await axiosInstance.post(`/api/groups/${groupId}/announcements`, announcementData);
    return response.data; 
  } catch (error) {
    console.error('Error adding announcement to Group:', error);
    throw new Error('Failed to add announcement to Group');
  }
};
export const addCommentToAnnouncement = async (announcementId, commentData) => {
  try {
    const response = await axiosInstance.post(`/api/announcements/${announcementId}/comments`, commentData);
    return response.data; 
  } catch (error) {
    console.error('Error adding comment to announcement:', error);
    throw new Error('Failed to add comment to announcement');
  }
};

export const addCommentToProjectAnnouncement = async (announcementId, commentData) => {
  try {
    const response = await axiosInstance.post(`/api/projectannouncements/${announcementId}/comments`, commentData);
    return response.data; 
  } catch (error) {
    console.error('Error adding comment to announcement:', error);
    throw new Error('Failed to add comment to announcement');
  }
};

export const addCommentToGroupAnnouncement = async (announcementId, commentData) => {
  try {
    const response = await axiosInstance.post(`/api/groupannouncements/${announcementId}/comments`, commentData);
    return response.data; 
  } catch (error) {
    console.error('Error adding comment to announcement:', error);
    throw new Error('Failed to add comment to announcement');
  }
};


export const fetchAnnouncementsForClass = async (classId) => {
  try {
    const response = await axiosInstance.get(`/api/classes/${classId}/announcements`);
    return response.data;
  } catch (error) {
    console.error('Error fetching announcements:', error);
    throw new Error('Failed to fetch announcements');
  }
};
export const fetchAnnouncementsForProject = async (projectId) => {
  try {
    const response = await axiosInstance.get(`/api/projects/${projectId}/announcements`);
    return response.data;
  } catch (error) {
    console.error('Error fetching announcements:', error);
    throw new Error('Failed to fetch announcements');
  }
};
export const fetchAnnouncementsForGroup = async (groupId) => {
  try {
    const response = await axiosInstance.get(`/api/groups/${groupId}/announcements`);
    return response.data;
  } catch (error) {
    console.error('Error fetching announcements:', error);
    throw new Error('Failed to fetch announcements');
  }
};


export const getTeacherByEmail = async (email) => {
  try {
    const response = await axiosInstance.get(`/api/teacher/${email}`);
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      throw new Error('Teacher not found');
    }
    console.error('Error fetching teacher:', error);
    throw new Error(error.response?.data?.message || 'Error fetching Teacher');
  }
};

export const AddMessage = async (identifiers, message) => {
  try {
    const response = await axiosInstance.put('/api/NativeChat/AddMessageData', {
      identifiers,
      message,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding message:', error);
    throw new Error(error.response?.data?.message || 'Failed to add message');
  }
};

export const AddMessagetoGroup = async (identifiers, message) => {
  try {
    const response = await axiosInstance.put('/api/NativeChat/AddMessageDataToGroup', {
      identifiers,
      message,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding message to group:', error);
    throw new Error(error.response?.data?.message || 'Failed to add message to group');
  }
};

export const deleteTeacherFromClassProjects = async (projectIds, teacherId) => {
  try {
    const response = await axiosInstance.post(`/api/projects/remove-teacher`, {
      projectIds,
      teacherId,
    });
    return response.data;
  } catch (error) {
    console.error('Error removing teacher from projects:', error);
    throw new Error('Failed to remove teacher from projects');
  }
};

export const deleteTeacherFromClass = async (classId, teacherId, token) => {
  try {
    const response = await axiosInstance.post(`/api/classes/${classId}/remove-teacher`, {
      teacherId,
      token
    });
    return response.data;
  } catch (error) {
    console.error('Error removing teacher from class:', error);
    throw new Error('Failed to remove teacher from class');
  }
};

export const deleteTeacherFromGroup = async (groupId, teacherId) => {
  try {
    const response = await axiosInstance.post(`/api/groups/${groupId}/remove-teacher`, {
      teacherId,
    });
    return response.data;
  } catch (error) {
    console.error('Error removing teacher from group:', error);
    throw new Error('Failed to remove teacher from group');
  }
};

export const uploadAssignment = async (formData) => {
  try {
    
    const response = await axiosInstance.post(`/api/uploadAssignment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading assignment:', error);
    throw new Error('Failed to upload assignment');
  }
};


export const fetchAssignmentsForTeacher = async (teacherId) => {
  try {
    const response = await axiosInstance.get(`/api/assignments/teacher/${teacherId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching assignments for teacher:', error);
    throw new Error('Failed to fetch assignments');
  }
};


export const getQuestionsByTeacherId = async (teacherId) => {
  try {
    const response = await axiosInstance.get(`/api/teacher/getQuestions/${teacherId}`);
    
    return response.data; 
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw new Error('Failed to fetch questions');
  }
};

export const saveConfig = async (configData) => {
  try {
    const response = await axiosInstance.post(`/api/saveConfig`, configData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error saving configuration:', error);
    throw new Error('Failed to save configuration');
  }
};
export const getConfig = async (teacherId) => {
  try {
    const response = await axiosInstance.get(`/api/getConfig/${teacherId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching configuration:", error);
    throw new Error("Failed to fetch configuration");
  }
};

export const getUserData = async (userId, userType) => {
  try {
    const response = await axiosInstance.get(`/api/getuserdata/${userId}/${userType}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};
