import { Box, Text, Image, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const ProjectCard = ({ project, classId, index }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    sessionStorage.setItem("activeClass", JSON.stringify(null));
    sessionStorage.setItem("activeNav", JSON.stringify(null));
    sessionStorage.setItem("activeProject", JSON.stringify(index));
    sessionStorage.setItem("activeGroup", JSON.stringify(null));
    const route = "/teacher-portal/class/" + classId + "/project/" + project._id;
    navigate(route);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      cursor="pointer"
      transform="scale(0.94)"
      transition="transform 0.2s ease"
      _hover={{ transform: "scale(1)" }}
      onClick={handleClick}
      mb={3}
    >
      <Image src={project.url} alt={project.projectName} />
      <VStack p={4} align="start">
        <Text fontWeight="semibold" fontSize="xl">{project.projectName}</Text>
        <Text>Goal: {project.projectDescription}</Text>
      </VStack>
    </Box>
  );
};

export default ProjectCard;
