import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Box,
  Text,
  Alert,
  AlertIcon,
  Fade,
  Select,
  Spinner,
  Toast,
  useToast
} from '@chakra-ui/react';
import { SimpleGrid } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse'
import { addStudentsToClass, createClass, createStudents } from '../../../Services/Teacher/teacherService.tsx'; // Import the service function
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';
import { useNavigate } from 'react-router-dom';

const CreateClass = ({ isOpen, onClose }) => {
  const [gradeLevel, setGradeLevel] = useState('');
  const [className, setClassName] = useState('');
  const [academicYear, setAcademicYear] = useState('');
  const [academicSemester, setAcademicSemester] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [days, setDays] = useState([]); 
  const [startTime, setStartTime] = useState(''); 
  const [endTime, setEndTime] = useState(''); 
  const token = sessionStorage.getItem("googleAccessToken");
  const toast = useToast();
  const navigate = useNavigate();


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv']
    },
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      csvParser(acceptedFiles[0]);
    }
  });

  const imageUrls = [
    "https://gstatic.com/classroom/themes/img_breakfast.jpg",
"https://gstatic.com/classroom/themes/Honors.jpg",
"https://gstatic.com/classroom/themes/img_graduation.jpg",
"https://gstatic.com/classroom/themes/img_bookclub.jpg",
"https://gstatic.com/classroom/themes/img_code.jpg",
"https://gstatic.com/classroom/themes/img_reachout.jpg",
"https://gstatic.com/classroom/themes/img_learnlanguage.jpg",
"https://gstatic.com/classroom/themes/img_backtoschool.jpg",
"https://gstatic.com/classroom/themes/WorldStudies.jpg",
"https://gstatic.com/classroom/themes/English.jpg",
"https://gstatic.com/classroom/themes/WorldHistory.jpg",
"https://gstatic.com/classroom/themes/SocialStudies.jpg",
"https://gstatic.com/classroom/themes/Geography.jpg",
"https://gstatic.com/classroom/themes/USHistory.jpg",
"https://gstatic.com/classroom/themes/Writing.jpg",
"https://gstatic.com/classroom/themes/LanguageArts.jpg",
"https://gstatic.com/classroom/themes/Geometry.jpg",
"https://gstatic.com/classroom/themes/Psychology.jpg",
"https://gstatic.com/classroom/themes/Math.jpg",
"https://gstatic.com/classroom/themes/Chemistry.jpg",
"https://gstatic.com/classroom/themes/Physics.jpg",
"https://gstatic.com/classroom/themes/Biology.jpg",
"https://gstatic.com/classroom/themes/img_coffee.jpg",
"https://gstatic.com/classroom/themes/img_cinema.jpg",
"https://gstatic.com/classroom/themes/img_violin2.jpg",
"https://gstatic.com/classroom/themes/img_arts.jpg",
"https://gstatic.com/classroom/themes/img_theatreopera.jpg",
"https://gstatic.com/classroom/themes/img_mealfamily.jpg",
"https://gstatic.com/classroom/themes/img_learninstrument.jpg",
"https://gstatic.com/classroom/themes/Design.jpg",
"https://gstatic.com/classroom/themes/img_concert.jpg",
"https://gstatic.com/classroom/themes/img_gamenight.jpg",
"https://gstatic.com/classroom/themes/img_handcraft.jpg",
"https://gstatic.com/classroom/themes/img_camping.jpg",
"https://gstatic.com/classroom/themes/img_hiking.jpg",
"https://gstatic.com/classroom/themes/img_hobby.jpg",
"https://gstatic.com/classroom/themes/img_sailing.jpg",
"https://gstatic.com/classroom/themes/img_videogaming.jpg",
"https://gstatic.com/classroom/themes/img_carmaintenance.jpg",

  ];
  

  const getRandomImageUrl = () => {
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    return imageUrls[randomIndex];
  };

  const [parsedData,setParsedData] = useState(null);

  const getEmails = () => {
    if (parsedData) {
      return parsedData.map(row => {
        if (row['Email Address']) {
          return row['Email Address'];
        }
        return null;
      }).filter(entry => entry !== null);
    }
    return [];
  };

  

  const getNames = () => {
    if (parsedData) {
      return parsedData.map(row => {
        if (row['Email Address']) {
          return row['First Name'] + ' ' + row['Last Name'];
        }
        return null;
      }).filter(entry => entry !== null);
    }
    return [];
  };

  const csvParser = (files) =>{
    console.log(files)
    Papa.parse(files,{
      complete:function(results){
          console.log(results.data)
          setParsedData(results.data);
      },
      header:true, 
      skipEmptyLines:true,
    })
  };


  const handleSubmit = async () => {

      sessionStorage.setItem("activeNav", JSON.stringify(0));
    if (!gradeLevel || !className || !academicYear || !academicSemester || !days.length || !startTime || !endTime) {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
      return;
    }

    let names;
    let mails;

    const schedule = days.map(day => ({
      day,
      startTime,
      endTime
    }));
  
    
    const selectedImageUrl = getRandomImageUrl();
    setIsLoading(true);

    const currentLoggedInTId = sessionStorage.getItem("CurrentLoggedInTId");
    const storedSidebarData = sessionStorage.getItem("sideBarData");

    if(file)
    {
        names = JSON.stringify(getNames());
        mails = JSON.stringify(getEmails());
        names = JSON.parse(names);
        mails = JSON.parse(mails);
        try{
          const cls = await createClass({
            gradeLevel,
            className,
            academicYear,
            academicSemester,
            url: selectedImageUrl,
            currentUserId: currentLoggedInTId?.toString() || "",
            schedule,
            token
          });
            const studentIds = await createStudents(mails);
            
              await addStudentsToClass(cls._id,studentIds, token);
              let sideBarData =  JSON.parse(storedSidebarData);

              const newClass = {
                _id: cls._id,
                classname: cls.classname,
                projects: [],

              };
              sideBarData?.classes.push(newClass);
              sessionStorage.setItem("sideBarData", JSON.stringify(sideBarData));
              handleClose();
              toast({
                description: 'Class Created successfully.',
                status: 'success',
                duration: 2000,
                isClosable: true,
              });
              const path = `teacher-portal/class/${cls._id}`;
              navigate(path);
            
        }
        
        catch (error) {
            console.error('Error creating class:', error);
            toast({
              title: "Error",
              description: "Class with provided class name already exist.",
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "bottom-center",
            });
          } finally {
            setIsLoading(false);
          }
    }
    else
    {
    try {
      const cls = await createClass({
        gradeLevel,
        className,
        academicYear,
        academicSemester,
        url: selectedImageUrl,
        currentUserId: currentLoggedInTId?.toString() || "",
        schedule,
        token
      });
      let sideBarData = JSON.parse(storedSidebarData);

              const newClass = {
                _id: cls._id,
                classname: cls.classname,
                projects: [],
              };
              sideBarData?.classes.push(newClass);
              sessionStorage.setItem("sideBarData", JSON.stringify(sideBarData));
      handleClose();
      toast({
        description: 'Class Created successfully.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      const path = `teacher-portal/class/${cls._id}`;
      navigate(path);

    } catch (error) {
      console.error('Error creating class:', error);
      toast({
        title: "Error",
        description: "Class with provided class name already exist.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-center",
      });
    } finally {
      setIsLoading(false);
    }
}
  };

  const handleClose = () => {
    setGradeLevel('');
    setClassName('');
    setAcademicYear('');
    setAcademicSemester('');
    setDays([]);
    setStartTime('');
    setEndTime('');
    setFile(null);
    onClose();
  };

  const currentYear = new Date().getFullYear();
  const academicYears = [currentYear, currentYear + 1, currentYear + 2];
  const semesters = ["Spring", "Summer", "Fall", "Winter"];

  
  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a Class</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {showError && (
            <Fade in={showError}>
              <Alert status="error" mb={4}>
                <AlertIcon />
                All fields are required.
              </Alert>
            </Fade>
          )}
          <VStack spacing={4} align="stretch">
            <SimpleGrid columns={12} spacing={4}>
              {/* Grade Level (Left) */}
              <Box gridColumn="span 6">
                <FormControl isRequired>
                  <Input
                    variant="flushed"
                    value={gradeLevel}
                    onChange={(e) => setGradeLevel(e.target.value)}
                    placeholder="Grade Level"
                    _placeholder={{ opacity: 0.7 }}
                  />
                </FormControl>
              </Box>
  
              {/* Academic Semester (Right) */}
              <Box gridColumn="span 6">
                <FormControl isRequired>
                  <Select
                    variant="flushed"
                    placeholder="Academic Semester"
                    value={academicSemester}
                    onChange={(e) => setAcademicSemester(e.target.value)}
                    cursor={'pointer'}
                  >
                    {semesters.map((semester) => (
                      <option key={semester} value={semester}>
                        {semester}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
  
              {/* Class Name (Left) */}
              <Box gridColumn="span 6" mt={4}>
                <FormControl isRequired>
                  <Input
                    variant="flushed"
                    value={className}
                    onChange={(e) => setClassName(e.target.value)}
                    placeholder="Class Name"
                    _placeholder={{ opacity: 0.7 }}
                  />
                </FormControl>
              </Box>
  
              {/* Academic Year (Right) */}
              <Box gridColumn="span 6" mt={4}>
                <FormControl isRequired>
                  <Select
                    variant="flushed"
                    placeholder="Academic Year"
                    value={academicYear}
                    onChange={(e) => setAcademicYear(e.target.value)}
                    cursor={'pointer'}
                  >
                    {academicYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
  
              {/* Days Selection (Better UI) */}
              <Box gridColumn="span 12" mt={4}>
                <FormControl>
                  <FormLabel>Days</FormLabel>
                  <SimpleGrid columns={7} spacing={2}>
                    {[
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                      'Sunday',
                    ].map((day) => (
                      <Button
                      key={day}
                      variant={days.includes(day) ? 'solid' : 'outline'}
                      bg={days.includes(day) ? 'gray.300' : 'white'}
                      color={days.includes(day) ? 'purple.900' : 'purple.900'}
                      borderColor={days.includes(day) ? 'gray.700' : 'gray.300'}
                      _hover={{
                        bg: days.includes(day) ? 'gray.300' : 'gray.100',
                      }}
                        onClick={() =>
                          setDays((prev) =>
                            prev.includes(day)
                              ? prev.filter((d) => d !== day)
                              : [...prev, day]
                          )
                        }
                      >
                        {day}
                      </Button>
                    ))}
                  </SimpleGrid>
                </FormControl>
              </Box>
  
              <Box gridColumn="span 6" mt={4}>
              <FormControl variant="floating">
                <Input
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  placeholder=" "
                  variant="flushed"
                  cursor={'text'}
                />
                <FormLabel mt={2}>Start Time</FormLabel>
              </FormControl>
            </Box>

            <Box gridColumn="span 6" mt={4}>
              <FormControl variant="floating">
                <Input
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  placeholder=" "
                  variant="flushed"
                  cursor={'text'}
                />
                <FormLabel mt={2}>End Time</FormLabel>
              </FormControl>
            </Box>

  
              {/* Attachment (Full Width) */}
              <Box gridColumn="span 12" mt={4}>
                <FormControl>
                  <Box
                    {...getRootProps()}
                    border="1px"
                    borderColor="gray.300"
                    p={4}
                    textAlign="center"
                    borderRadius="md"
                    cursor="pointer"
                    _hover={{ bg: 'gray.100' }}
                  >
                    <input {...getInputProps()} />
                    {file ? (
                      <Text>{file.name}</Text>
                    ) : (
                      <Text>Drag 'n' drop a CSV file here, or click to select one</Text>
                    )}
                  </Box>
                </FormControl>
              </Box>
            </SimpleGrid>
  
            {/* Submit Button */}
            <Button
              bg="purple.900"
              _hover={{ bg: 'purple.900' }}
              color="white"
              onClick={handleSubmit}
              isDisabled={isLoading}
              mt={4}
              mb={4}
            >
              {isLoading ? <LoadingSpinner /> : 'Submit'}
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
  
  

};

export default CreateClass;
