import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Input,
  Textarea,
  VStack,
  CircularProgress,
  Image,
  Select,
  Button,
  Spinner,
  HStack,
  Avatar
} from '@chakra-ui/react';
import { FiSend, FiSearch, FiCopy, FiCheck, FiX, FiMaximize, FiMinimize, FiTag, FiHelpCircle, FiMessageCircle, FiChevronLeft, FiPaperclip } from 'react-icons/fi';
import { FaArrowLeftLong } from "react-icons/fa6";
import { getStudentsByIds, getProjectsByUserId, getTeachersByIds, getClassesByUserId, getConfig } from '../../Services/Teacher/teacherService.tsx';
import { appendGPT,appendGroupGPT,appendProjectGPT, getChatGPTResponse } from '../../Services/ChatGPT/Student/ChatGPTService.tsx';
import chatgptwhite from '../../Assets/gptwhite.png'; 
import {  useParams, useNavigate } from 'react-router-dom';
import { Rnd } from "react-rnd";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { pusherClient } from '../../pusherConfig.js';
import giimage from '../../Assets/G-Ideas.png'
import * as mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import { motion } from 'framer-motion'; 
import azureAI from '../../Assets/azureAI.png';
pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();


const transitionDuration = 1;
const MAX_FILE_SIZE_MB = 5;


const ChatGPTChat = ({ onClose }) => {
  const [student, setStudent] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [isTextArea, setIsTextArea] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [students, setStudents] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [highlightText, setHighlightText] = useState('');
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({ projectName: 'Private', _id: 'private' });
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const { projectId, groupId } = useParams();
  const navigate =  useNavigate();
  const [classes,setClasses] = useState([]);
  const [selectedText, setSelectedText] = useState('');
  const [userMap, setUserMap] = useState({});
  const [selectedAI, setSelectedAI] = useState("gpt");
  const [chatHeading, setChatHeading] = useState("ChatGPT")
  const [currentTeacherId, setCurrentTeacherId] = useState("");



  const currentLoggedInSId = sessionStorage.getItem("CurrentLoggedInSId").trim();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const cls = await getClassesByUserId(currentLoggedInSId,"student");
        setClasses(cls);
        const data = await getProjectsByUserId(currentLoggedInSId,"student");
        if(data.message)
        setProjects([{ projectName: 'Private', _id: 'private' }]);
        else 
        setProjects([{ projectName: 'Private', _id: 'private' }, ...data]);
        const students = await getStudentsByIds([currentLoggedInSId]);
        setStudent(students[0]);
        if(!groupId && !projectId){
        setMessages(students[0]?.gpt);
        setFilteredMessages(students[0]?.gpt);
        }

      } catch (error) {
        console.error('Error fetching projects:', error);
      }finally {
        setIsLoading(false);
      }
    };
    fetchProjects();
  }, []);

  
  useEffect(() => {
    const fetchData = async () => {
    if (projects.length > 0 && projectId) {
      const project = projects.find(proj => proj._id === projectId);
      setSelectedProject(project);
      setCurrentTeacherId(project.teacherIds[0]);
      const config = await getConfig(project.teacherIds[0]);
        if (config){
        setSelectedAI(config.selectedAI);
        if(config.selectedAI === "azureAI"){
          setChatHeading("AzureAI");
        }
        else{
          setChatHeading("ChatGPT");
        }
        }
        const students = await getStudentsByIds(project.studentIds || [currentLoggedInSId]);
        const teachers = await getTeachersByIds(project.teacherIds);
        const allUsers = [...students, ...teachers];
        setStudents(allUsers);

        const userMap = {};
        allUsers.forEach(user => {
          userMap[user._id] = {
            name: user.name,
            email: user.email,
            photoURL: user.photoURL,
            type: user.type 
          };
        });
        setUserMap(userMap);
      if(groupId){
        setGroups([{ groupName: 'Select group', _id: '' }, ...project.groups])
        const group = project.groups.find(grp => grp._id === groupId);
        setSelectedGroup(group);
        const allMessages = group.chatgptmessages;
      const pairedMessages = allMessages.reduce((acc, curr, index, arr) => {
        if (curr.role === 'user' && arr[index + 1]?.role === 'assistant') {
          acc.push({
            query: curr,
            response: arr[index + 1],
          });
        }
        return acc;
      }, []);
      const recentMessages = pairedMessages.slice(-100);
      setMessages(recentMessages);
        setFilteredMessages(recentMessages);
      } if(projectId && !groupId){
        const allMessages = project.chatgptmessages;
        const pairedMessages = allMessages.reduce((acc, curr, index, arr) => {
          if (curr.role === 'user' && arr[index + 1]?.role === 'assistant') {
            acc.push({
              query: curr,
              response: arr[index + 1],
            });
          }
          return acc;
        }, []);
        const recentMessages = pairedMessages.slice(-100);
        setMessages(recentMessages);
        setFilteredMessages(recentMessages);
      }
      
    }
  };
  fetchData();
}, [projects, projectId, groupId]);

  
  
  const handleProjectChange = async(event) => {
    const selectedProjectId = event.target.value;
    const project = projects.find(proj => proj._id === selectedProjectId);
    setSelectedProject(project);
    if(project.projectName != 'Private'){
    setCurrentTeacherId(project?.teacherIds[0]);
    }
    else{
      setCurrentTeacherId("");
    }
    if (project.projectName != 'Private') {
    const config = await getConfig(project.teacherIds[0]);
        if (config){
        setSelectedAI(config.selectedAI);
        if(config.selectedAI === "azureAI"){
          setChatHeading("AzureAI");
        }
        else{
          setChatHeading("ChatGPT");
        }
        }
      }
    setSelectedGroup("");

    let updatedMessages = [];
  
      if (project.projectName === 'Private') {
        updatedMessages = student?.gpt || [];
      } else {
        const allMessages = project.chatgptmessages;
        const pairedMessages = allMessages.reduce((acc, curr, index, arr) => {
          if (curr.role === 'user' && arr[index + 1]?.role === 'assistant') {
            acc.push({
              query: curr,
              response: arr[index + 1],
            });
          }
          return acc;
        }, []);
        updatedMessages = pairedMessages;
      }
  
      const recentMessages = updatedMessages.slice(-100);
  
      if (JSON.stringify(filteredMessages) !== JSON.stringify(updatedMessages)) {
        setMessages(recentMessages);
        setFilteredMessages(recentMessages);
      }

      const students = await getStudentsByIds(project.studentIds || [currentLoggedInSId]);
        const teachers = await getTeachersByIds(project.teacherIds);
        const allUsers = [...students, ...teachers];
        setStudents(allUsers);

        const userMap = {};
        allUsers.forEach(user => {
          userMap[user._id] = {
            name: user.name,
            email: user.email,
            photoURL: user.photoURL,
            type: user.type 
          };
        });
        setUserMap(userMap);
  
    if (project && project.projectName !== 'Private') {
      setGroups([{ groupName: 'Select group', _id: '' }, ...project.groups]);
    } else {
      setGroups([]);
    }
  };

  
  
  const handleGroupChange = (event) => {
    const selectedGroupId = event.target.value;
    const group = groups.find(grp => grp._id === selectedGroupId);
    setSelectedGroup(group);
    
      const allMessages = group.chatgptmessages;
      const pairedMessages = allMessages.reduce((acc, curr, index, arr) => {
        if (curr.role === 'user' && arr[index + 1]?.role === 'assistant') {
          acc.push({
            query: curr,
            response: arr[index + 1],
          });
        }
        return acc;
      }, []);
      const recentMessages = pairedMessages.slice(-100);
  
      if (JSON.stringify(filteredMessages) !== JSON.stringify(recentMessages)) {
        setMessages(recentMessages);
        setFilteredMessages(recentMessages);
      }
  };

  useEffect(() => {
    setIsTextArea(input?.split('\n').length > 1 || /\s{2,}/.test(input));
  }, [input]);

  const [NewMessagefromNew, setNewMessagefromNew] = useState([]);




  const handleSendMessage = async (cData) => {
    if (input.trim()  === '' && cData.trim() === '') {
      return;
    }
    else if(input.toLowerCase() === "start" ){
      setGImessages([{ role: 'gi', content: qta[selectedOption].questions[0] }]);
      setInput('');
      setCurrentQuestionIndex(0);
      setCompleted(false);
      return;
    }
    setLoading(true);
    let userMessage;
    let completeData;
  if(isClicked && selectedOption){
    
    if(input.trim()  !== ''){
      
      if(problem!=='' || knowledge!=='' || steps!==''){
      completeData = 
      `G-Ideas: What challenge or problem are you trying to solve?\nUser response: ${problem}\n` +
      `G-Ideas: What do you know about this challenge already, and what do you need to know (e.g., questions, problems): \nUser response: ${knowledge}\n` +
      `G-Ideas: What may be your first steps to work on this problem?\nUser response: ${steps}\n` +
      `${input}`;
      }
      else if(gi1!=='' || gi2!==''){
        completeData = 
        `G-Ideas: I’d love to help. What’s the problem or issue you are thinking about?\nuser response: ${gi1}\n` +
      `G-Ideas: What are your initial ideas? You can type here or copy/paste from your personal notes or group discussion. \nUser response: ${gi2}\n` +
      `${input}`;

      }
      else if(fp1!=='' || fp2!==''){
        completeData = 
        `G-Ideas: What is your presentation about?\nuser response: ${fp1}\n` +
      `G-Ideas: What’s your plan or outline? \nUser response: ${fp2}\n` +
      `${input}`;

      }
      else if(fw1!=='' || fw2!==''){
        completeData = 
        `G-Ideas: What are you writing about?\nuser response: ${fw1}\n` +
      `G-Ideas: What’s your plan or outline? \nUser response: ${fw2}\n` +
      `${input}`;
      }
      else if(selectedFile && fileData){
        completeData = 
        `G-Ideas: Sure. Please give me your meeting notes.\nUser response: ${fileData}\n` +
        `${input}`;
      }
      else if(!selectedFile && smn!==''){
        completeData = 
        `G-Ideas: Sure. Please give me your meeting notes.\nUser response: ${smn}\n` +
        `${input}`;
      }

      else if(dma!==''){
        completeData =
          `G-Ideas: What is your meeting about? What do you hope to achieve? Feel free to tell me what have been discussed at any past meetings.\nUser response: ${dma}\n`+
          `${input}`;
      }
      else if(ttm1!=='' && ttm2!==''){
        completeData =
              `G-Ideas: What kind of meeting are you conducting?\nUser response: ${ttm1}\n` +
              `G-Ideas: Any concerns or problems you have experienced in the meetings?\nUser response: ${ttm2}\n` +
              `${prompt}`;
      }
   
    setInput("");
    userMessage = { role: 'user', userId: student._id, content: completeData, timestamp: Date.now() };
    }
    else
    userMessage = { role: 'user', userId: student._id, content: cData, timestamp: Date.now() };
  }
  else{
    userMessage = { role: 'user', userId: student._id, content: input, timestamp: Date.now() };
  }
    NewMessagefromNew.push(userMessage);
    if (selectedProject._id === 'private') {
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setFilteredMessages(prevMessages => [...prevMessages, userMessage]);
    }
    
    setInput('');
  
    try {
      let lastMessages;
      if (selectedProject._id === 'private') {
      lastMessages = messages.slice(-15); 
      }
      else{
        lastMessages = messages.slice(-8).reduce((acc, pair) => {
          return [...acc, pair.query, pair.response];
        }, []);
      }
      let response;
      if(currentTeacherId){
       response = await getChatGPTResponse([...lastMessages, userMessage], currentTeacherId);
      }
      else{
         response = await getChatGPTResponse([...lastMessages, userMessage], student._id);
      }
      const assistantMessage = { role: 'assistant', userId: student._id, content: response.data, timestamp: Date.now() };
      
      NewMessagefromNew.push(assistantMessage);
  
      if (selectedProject._id === 'private') {
        setMessages(prevMessages => [...prevMessages, assistantMessage]);
        setFilteredMessages(prevMessages => [...prevMessages, assistantMessage]);
        await appendGPT(NewMessagefromNew, student._id);
      } else {
        if (selectedGroup) {
          await appendGroupGPT(NewMessagefromNew, selectedGroup._id);
        } else {
          await appendProjectGPT(NewMessagefromNew, selectedProject._id);
        }
      }
      
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setNewMessagefromNew([]);
      setLoading(false);
      setIsClicked(false);
    }
  };

  const handleCopyMessage = (content) => {
    navigator.clipboard.writeText(content);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 2000);
  };

  const handleSearch = () => {
    if (searchQuery.trim()) {
      if (selectedProject._id === 'private') {
      const filtered = messages.filter(msg =>
        msg.content.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredMessages(filtered);
      setHighlightText(searchQuery);
      }
      else{
        const filtered = messages.filter(msg => 
          msg.query.content.toLowerCase().includes(searchQuery.toLowerCase()) || msg.response.content.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setFilteredMessages(filtered);
      setHighlightText(searchQuery);
      }
    } else {
      setFilteredMessages(messages);
      setHighlightText('');
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCloseSearch = () => {
    setIsSearchOpen(false);
    setSearchQuery('');
    setFilteredMessages(messages);
    setHighlightText('');
  };

  const getHighlightedText = (text, highlight) => {
    if (!text || !highlight) {
      return text;
    }
  
    
    const content = typeof text === 'string' ? text : text?.props?.children;
  
    
    if (!content || typeof content !== 'string') {
      return text;
    }
  
    const parts = content?.split(new RegExp(`(${highlight})`, 'gi'));
  
    return parts?.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase()
        ? <span key={index} style={{ backgroundColor: 'purple', color: 'white' }}>{part}</span>
        : part
    );
  };
  

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = (event.clipboardData || window.clipboardData).getData('text');
    const newText = paste.replace(/\r?\n/g, '\n'); 
    setInput((prevInput) => prevInput + newText);
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 2000);
  };

  const extractLanguage = (code) => {
    const match = code.match(/^```(\w+)/);
    return match ? match[1] : 'text';
  };

  const parseMessageContent = (content, highlightText) => {
    const parts = content?.split(/(```[\s\S]*?```)/g);
  
    return parts?.map((part, idx) => {
      if (part.startsWith('```') && part.endsWith('```')) {
        // Handle code blocks
        return (
          <Box
            key={idx}
            as="pre"
            bg="black"
            color="white"
            p={2}
            borderRadius="md"
            overflowX="auto"
            width="80%"
            ml={15}
            mb={2}
            position="relative"
          >
            <SyntaxHighlighter
              language={extractLanguage(part)}
              style={solarizedlight}
              customStyle={{
                backgroundColor: 'transparent',
                padding: 0,
                margin: 0,
                borderRadius: 0,
                width: '90%',
              }}
            >
              {/* Apply highlighting to code */}
              {getHighlightedText(part.slice(3, -3), highlightText)}
            </SyntaxHighlighter>
            <IconButton
              size="xs"
              icon={<FiCopy />}
              aria-label="Copy code"
              bg={'black'}
              color={'white'}
              _hover={{ bg: 'white', color: 'black' }}
              onClick={() => handleCopyCode(part.slice(3, -3))}
              position="absolute"
              top="2px"
              right="2px"
              zIndex={1}
            />
          </Box>
        );
      }
  
      // Handle text parts
      return (
        <Text key={idx} whiteSpace="pre-wrap" mb={2}>
          {getHighlightedText(part, highlightText)}
        </Text>
      );
    });
  };

  const [width, setWidth] = useState(630);
  const [height, setHeight] = useState(500);
  const screenWidth = window.screen.width;
  const [x, setX] = useState(() => calculateInitialX());
  const [y, setY] = useState(() => calculateInitialY());
  const [shouldTransition, setShouldTransition] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  function calculateInitialX() {
    if (window.innerWidth < 1440) {
      return 400; 
    } else {
      return 530; 
    }
  }

  // Function to calculate initial Y position
  function calculateInitialY() {
    if (window.innerWidth < 1440) {
      return 366; 
    } else {
      return 190; 
    }
  }



  const handleResize = () => {
    setShouldTransition(true);
    if (!isExpanded) {
      setWidth(window.innerWidth * 0.8);
      setHeight(window.innerHeight * 0.8);
      setX(window.innerWidth * 0.009);
      setY(window.innerHeight * 0.11);
    } else {
      setWidth(630);
      setHeight(500);
      if (screenWidth < 1440) {
        setX(440); 
      setY(366); 
    } else {
      setX(530); 
      setY(190);  
      }
    }
    setIsExpanded(!isExpanded);
    setTimeout(() => {
      setShouldTransition(false);
    }, 500);
  };



  const handleTagClick = (pair) => {
    let data = {};
    const cls = classes.filter(classItem => classItem.projects.includes(selectedProject._id));
  
    if ((pair.query.role === 'user' && pair.response.role === 'assistant') || selectedText) {
      data = selectedText ? selectedText : {
        query: pair.query.content,
        response: pair.response.content,
        sentTime: pair.response.timestamp, 
        userId: pair.query.userId 
      };
  
      if (!selectedGroup) {
        sessionStorage.setItem("activeTabInProject", "3");
        sessionStorage.setItem("activeNav", null);
        sessionStorage.setItem("activeGroup", null);
        sessionStorage.setItem("activeProject", null);
        navigate(`/student-portal/class/${cls[0]._id}/project/${selectedProject._id}`, { state: { prefillMessage: data } });
      } else {
        sessionStorage.setItem("activeTabInGroup", "2");
        sessionStorage.setItem("activeNav", null);
        sessionStorage.setItem("activeGroup", null);
        sessionStorage.setItem("activeProject", null);
        navigate(`/student-portal/class/${cls[0]._id}/project/${selectedProject._id}/group/${selectedGroup._id}`, { state: { prefillMessage: data } });
      }
    }
  
    setSelectedText('');
  };

  
  useEffect(() => {
    let projectChannel;
    let groupChannel;
  
    if (selectedGroup) {
      groupChannel = pusherClient.subscribe(`chatgpt-${selectedGroup._id}`);
      groupChannel.bind('groupgptmessages:new', (data) => {
        const pairedMessages = data.messages.reduce((acc, curr, index, arr) => {
          if (curr.role === 'user' && arr[index + 1]?.role === 'assistant') {
            acc.push({
              query: curr,
              response: arr[index + 1],
            });
          }
          return acc;
        }, []);
        setMessages(prevMessages => [...prevMessages, ...pairedMessages]);
        setFilteredMessages(prevMessages => [...prevMessages, ...pairedMessages]);
      });
    } else if (selectedProject) {
      projectChannel = pusherClient.subscribe(`chatgpt-${selectedProject._id}`);
      projectChannel.bind('projectgptmessages:new', (data) => {
        const pairedMessages = data.messages.reduce((acc, curr, index, arr) => {
          if (curr.role === 'user' && arr[index + 1]?.role === 'assistant') {
            acc.push({
              query: curr,
              response: arr[index + 1],
            });
          }
          return acc;
        }, []);
        setMessages(prevMessages => [...prevMessages, ...pairedMessages]);
        setFilteredMessages(prevMessages => [...prevMessages, ...pairedMessages]);
      });
    }
  
    return () => {
      
      if (projectChannel) {
        projectChannel.unbind('projectgptmessages:new');
        pusherClient.unsubscribe(`chatgpt-${selectedProject._id}`);
      }
  
      if (groupChannel) {
        groupChannel.unbind('groupgptmessages:new');
        pusherClient.unsubscribe(`chatgpt-${selectedGroup._id}`);
      }
    };
  }, [selectedProject, selectedGroup]);
  

  
   const [isClicked, setIsClicked] = useState(true);
   const stored = sessionStorage.getItem("studentUserData");
      const user = JSON.parse(stored);

      const [selectedOption, setSelectedOption] = useState(null);

      
      const [problem, setProblem] = useState('');
  const [knowledge, setKnowledge] = useState('');
  const [steps, setSteps] = useState('');
  const [gi1, setGi1] = useState('');
  const [gi2, setGi2] = useState('');
  const [tm1, setTm1] = useState('');
  const [tm2, setTm2] = useState('');
  const [fp1, setFp1] = useState('');
  const [fp2, setFp2] = useState('');
  const [fw1, setFw1] = useState('');
  const [fw2, setFw2] = useState('');
  const [ttm1, setTtm1] = useState('');
  const [ttm2, setTtm2] = useState('');
  const [smn, setsmn] = useState('');
  const [dma, setdma] = useState('');

  const handlePromptClick = (event) => {
    const target = event.target;
    const prompt = target.innerHTML;
  
    let completeData = '';
  
    // Dynamic content based on selectedOption
    switch (selectedOption) {
      case 'define_problem':
        completeData = 
          `G-Ideas: What challenge or problem are you trying to solve?\nUser response: ${problem}\n` +
          `G-Ideas: What do you know about this challenge already, and what do you need to know (e.g., questions, problems): \nUser response: ${knowledge}\n` +
          `G-Ideas: What may be your first steps to work on this problem?\nUser response: ${steps}\n` +
          `${prompt}`;
        break;
  
      case 'generate_ideas':
        completeData = 
          `G-Ideas: I’d love to help. What’s the problem or issue you are thinking about?\nUser response: ${gi1}\n` +
          `G-Ideas: What are your initial ideas? You can type here or copy/paste from your personal notes or group discussion. \nUser response: ${gi2}\n` +
          `${prompt}`;
        break;
  
      case 'feedback_presentation':
        completeData = 
          `G-Ideas: What is your presentation about?\nUser response: ${fp1}\n` +
          `G-Ideas: What’s your plan or outline? \nUser response: ${fp2}\n` +
          `${prompt}`;
        break;
  
      case 'feedback_writing':
        completeData = 
          `G-Ideas: What are you writing about?\nUser response: ${fw1}\n` +
          `G-Ideas: What’s your plan or outline? \nUser response: ${fw2}\n` +
          `${prompt}`;
        break;

        case 'draft_meeting_agenda':
          completeData =
          `G-Ideas: What is your meeting about? What do you hope to achieve? Feel free to tell me what have been discussed at any past meetings.\nUser response: ${dma}\n`+
          `${prompt}`;
          break;

        case 'summarize_meeting_notes':
            if(selectedFile){
              completeData =
              `G-Ideas: Sure. Please give me your meeting notes.\nUser response: ${fileData}\n`+
              `${prompt}`;
              break;
            }
            else if(!selectedFile && smn!==''){
              completeData =
              `G-Ideas: Sure. Please give me your meeting notes.\nUser response: ${smn}\n`+
              `${prompt}`;
              break;
            }
            

        case 'tips_team_meeting':
              completeData =
              `G-Ideas: What kind of meeting are you conducting?\nUser response: ${ttm1}\n` +
              `G-Ideas: Any concerns or problems you have experienced in the meetings?\nUser response: ${ttm2}\n` +
              `${prompt}`;
              break;
  
      default:
        console.error('Unknown option');
    }

    handleSendMessage(completeData);
  };
  

  const qta = {
    define_problem: {
      questions: [
        "Thanks for inviting me to think with you. First, please help me understand: what challenge or problem are you trying to solve?",
        "What do you know about this challenge already, and what do you need to know (e.g., questions, problems)?",
        "What may be your first steps to work on this problem?"
      ],
      prompts: [
        "Why is this problem important to solve? Why does it matter, and to whom?",
        "What are other people or organizations doing to deal with this challenge?",
        "Suggest a few solid resources about this challenge at my reading level"
      ]
    },
    generate_ideas: {
      questions: [
        "I’d love to help. What’s the problem or issue you are thinking about?",
        "What are your initial ideas? You can type here or copy/paste from your personal notes or group discussion."
      ],
      prompts: [
        "Generate a few more ideas to help expand our thinking",
        "Among the ideas we discussed, which ideas are most promising?",
        "What do we need to better understand or look into?",
        "What are the main points of disagreements in our discussion?"
      ]
    },
    draft_meeting_agenda: {
      questions: [
        "What is your meeting about? What do you hope to achieve? Feel free to tell me what have been discussed at any past meetings."
      ],
      prompts: [
        "Generate an agenda.",
      ]
    },
    summarize_meeting_notes: {
      questions: [
        "Sure. Please give me your meeting notes."
      ],
      prompts: [
        "Generate a summary in the format of meeting date, attendees, issues discussed, questions raised, next step",
      ]
    },
    tips_team_meeting: {
      questions: [
        "What kind of meeting are you conducting?",
        "Any concerns or problems you have experienced in the meetings?"
      ],
      prompts: [
        "Generate a short list of suggestions for how to run this type of meeting.",
      ]
    },
    feedback_presentation: {
      questions: [
        "What is your presentation about?",
        "What’s your plan or outline?"
      ],
      prompts: [
        "Feedback on my plan/outline",
        "Feedback on my draft slides: How may I improve?",
        "Tips for oral presentation",
      ]
    },
    feedback_writing: {
      questions: [
        "What are you writing about?",
        "What’s your plan or outline?"
      ],
      prompts: [
        "Feedback on my outline",
        "Feedback on my draft writing: How can I improve?",
        "Tips for writing this type of reports"

      ]
    }
  };

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
const [GImessages, setGImessages] = useState([]);
const [isTyping, setIsTyping] = useState(false);
const [completed, setCompleted] = useState(false);
const [onTeam, setOnTeam] = useState(false);

const handleClick = (option) => {
  if(selectedOption && selectedOption === "team_meeting"){
    setOnTeam(true);
  }
  setSelectedOption(option);
  setDummySelectedOption(option);
  if(GImessages.length === 0 || dummySelectedOption != option ){
  setGImessages([{ role: 'gi', content: qta[option]?.questions[0] }]);
  setCurrentQuestionIndex(0);
  setCompleted(false);
  }
};


const handleAnswerSubmit = (answer) => {
  if(answer!==''){
    const stateMapping = {
      define_problem: [setProblem, setKnowledge, setSteps], 
      generate_ideas: [setGi1, setGi2], 
      team_meeting: [setTm1, setTm2], 
      feedback_presentation: [setFp1, setFp2], 
      feedback_writing: [setFw1, setFw2], 
      draft_meeting_agenda: [setdma],
      summarize_meeting_notes: [setsmn],
      tips_team_meeting: [setTtm1,setTtm2],
    };
    if (
      stateMapping[selectedOption] &&
      currentQuestionIndex < stateMapping[selectedOption].length
    ) {
      stateMapping[selectedOption][currentQuestionIndex](answer);
    }
  setGImessages((prev) => [...prev, { role: 'user', content: answer }]);
  setInput(''); 
  setIsTyping(true);

  setTimeout(() => {
    if (currentQuestionIndex < qta[selectedOption].questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setGImessages((prev) => [...prev, { role: 'gi', content: qta[selectedOption].questions[currentQuestionIndex + 1] }]);
    } else {
      const promptsMessage = qta[selectedOption].prompts
        .map((prompt, index) => `<a href="#" class="prompt-link" data-prompt="${prompt}" style="color:blue; display: block; margin-bottom: 8px;">${prompt}</a>`)
        .join('<br/>');

      setGImessages((prev) => [
        ...prev,
        {
          role: 'gi',
          content: `
            <div>
              <strong>G-Ideas: How can AI help? Use any of the following prompts:</strong><br/><br/>
              ${promptsMessage}<br/><br/>
              <span>Type your own request or type 'start' to start from beginning</span>
            </div>
          `
        }
      ]);
      setCompleted(true);
    }
    setIsTyping(false);
  }, 1000); 
}
};
const [dummySelectedOption, setDummySelectedOption] = useState(null)
const handleBackClick = () => {
  if(selectedOption === "draft_meeting_agenda" || selectedOption === "summarize_meeting_notes" || selectedOption === "tips_team_meeting"){
    setOnTeam(false);
    setSelectedOption("team_meeting");
    setSelectedFile(null);
  }
  else{
  setDummySelectedOption(selectedOption);
  setSelectedOption(null);
  setOnTeam(false);
  
  }
}


const [selectedFile, setSelectedFile] = useState(null);
const [fileData, setFileData] = useState('');

const extractFileData = async (file) => {
  
  if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
    alert(`File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`);
    return null;
  }

  const fileType = file.type;

  if (fileType.includes('pdf')) {
    
    const pdfData = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
    let text = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const pageText = await page.getTextContent();
      text += pageText.items.map(item => item.str).join(' ');
    }
    return text;
  } else if (fileType.includes('word') || fileType.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')) {
    
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    return result.value; 
  } else if (fileType.includes('text/plain')) {
    
    const textData = await file.text();
    return textData;
  } else {
    alert('Unsupported file type.');
    return null;
  }
};



const handleFileChange = async(e) => {
  const file = e.target.files[0];
  if (file) {
    setSelectedFile(file);
    const data = await extractFileData(file);
    console.log(data);
    setFileData(data);
    
    setGImessages((prev) => [
      ...prev,
      {
        role: 'user',
        content: `
        <div>
            <a href="${URL.createObjectURL(file)}" style="color:blue;" target="_blank" rel="noopener noreferrer" class="file-link">
              ${file.name}
            </a>
          </div>
        `
      }
    ]);

    
    setTimeout(() => {
      const promptsMessage = qta[selectedOption].prompts
        .map((prompt, index) => `<a href="#" class="prompt-link" data-prompt="${prompt}" style="color:blue; display: block; margin-bottom: 8px;">${prompt}</a>`)
        .join('<br/>');

      
      setGImessages((prev) => [
        ...prev,
        {
          role: 'gi',
          content: `
            <div>
              <strong>G-Ideas: How can AI help? Use any of the following prompts:</strong><br/><br/>
              ${promptsMessage}<br/><br/>
              <span>Type your own request or type 'start' to start from beginning</span>
            </div>
          `
        }
      ]);

      setCompleted(true);
    }, 1000); 
  }
};


      
    return (
      <Rnd
      size={{ width, height }}
      position={{ x, y }}
      minWidth={300}
      minHeight={400}
      dragHandleClassName="handle"
      
      onResizeStop={(e, direction, ref, delta, position) => {
        setWidth(ref.style.width);
        setHeight(ref.style.height);
      }}
      onDragStop={(e, d) => {
        setX(d.x);
        setY(d.y);
      }}
      style={{
        transition: shouldTransition ? 'all 0.5s ease-in-out' : 'none',
      }}
      
    >
        <Box
          width="100%"
          height="100%"
          bg="gray.50"
          color="black"
          borderRadius="xl"
          display="flex"
          flexDirection="column"
          boxShadow="2xl"
          fontFamily="initial"
          fontSize="16"
          zIndex={102}
        >
          {showCopied && (
            <Box
              position="absolute"
              bottom="6"
              left="50%"
              transform="translateX(-50%)"
              bg="purple.900"
              color="white"
              p={2}
              borderRadius="xl"
              boxShadow="md"
              zIndex="docked"
              width="20%"
              textAlign="center"
            >
              Copied!
            </Box>
          )}
          <Flex
            alignItems="center"
            justifyContent="space-between"
            bg="purple.900"
            p={2}
            borderTopRadius="xl"
            borderBottom="1px solid"
            borderColor="gray.200"
            color="white"
            position="relative"
            
          >
            <Flex className="handle" cursor="move"  alignItems="center">
            <Image 
              src={selectedAI === 'azureAI' ? azureAI : chatgptwhite} 
              
              width={"50px"}
              height={"52px"}
              
            />

              {!isSearchOpen && (
                <Text fontWeight="bold" fontSize="xl">{chatHeading}</Text>
              )}
            </Flex>
            <Flex alignItems="center" position="relative">
              <Select
                value={selectedProject._id}
                onChange={handleProjectChange}
                bg="purple.700"
                borderColor="purple.600"
                color="white"
                mr={2}
                width="130px"
              >
                {projects.map((project) => (
                  <option key={project._id} value={project._id}>
                    {project.projectName}
                  </option>
                ))}
              </Select>
              <Select
                value={selectedGroup?._id}
                onChange={handleGroupChange}
                bg="purple.700"
                borderColor="purple.600"
                color="white"
                mr={2}
                width="130px"
              >
                {groups.map((group) => (
                  <option key={group._id} value={group._id}>
                    {group.groupName}
                  </option>
                ))}
              </Select>
              {isSearchOpen ? (
                <Flex alignItems="center" ml={2}>
                  <Input
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    color="white"
                    bg="purple.700"
                    borderColor="purple.600"
                    flex="1"
                    height="40px"
                    minWidth="0"
                    mr={2}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }}
                  />
                  <IconButton
                    size="sm"
                    icon={<FiCheck />}
                    aria-label="Submit search"
                    variant="ghost"
                    color="white"
                    onClick={handleSearch}
                    ml={1}
                    _hover={{ color: "white" }}
                  />
                  <IconButton
                    size="sm"
                    icon={<FiX />}
                    aria-label="Close search"
                    variant="ghost"
                    color="white"
                    onClick={handleCloseSearch}
                    ml={1}
                    _hover={{ color: "white" }}
                  />
                </Flex>
              ) : (
                <IconButton
                  size="sm"
                  icon={<FiSearch />}
                  aria-label="Open search"
                  variant="ghost"
                  color="white"
                  onClick={() => setIsSearchOpen(true)}
                  ml={2}
                  _hover={{ color: "white" }}
                />
              )}
              <IconButton
              size="m"
              icon={isClicked ? <FiMessageCircle /> : <FiHelpCircle />}
              aria-label={isClicked ? "Help" : "Chat"}
              variant="ghost"
              color="white"
              onClick={() => (setIsClicked(!isClicked), setSelectedOption(dummySelectedOption))}
              ml={6}
              pl={5}
              _hover={{ color: "white" }}
            />
              <IconButton
              size="sm"
              icon={isExpanded ? <FiMinimize />: <FiMaximize />}
              aria-label={isExpanded ? "Minimize chat" : "Resize chat"}
              variant="ghost"
              color="white"
              _hover={{color:'white'}}
              onClick={handleResize}
              ml={6}
          />
              <IconButton
                size="sm"
                icon={<FiX />}
                aria-label="Close"
                variant="ghost"
                color="white"
                onClick={onClose}
                ml={2}
                _hover={{ color: "white" }}
              />
            </Flex>
          </Flex>
          {isClicked ? (
            !selectedOption ? (
              
              <VStack spacing={4} align="flex-start" p={4} width={"100%"} height={"100%"}>
                <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: transitionDuration }}
              >
                <Text fontSize="xl" fontWeight="bold">
                  Hi {user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1).toLowerCase()}, how can I help?
                </Text>
                </motion.div>
                <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: transitionDuration }}
              >
                <Box>
                  <Button variant="outline" colorScheme="purple" onClick={() => handleClick('define_problem')}>
                    Define problem & plan research
                  </Button>
                </Box>
                </motion.div>
                <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: transitionDuration }}
              >
                <Box>
                  <Button variant="outline" colorScheme="purple" onClick={() => handleClick('generate_ideas')}>
                    Generate & improve ideas
                  </Button>
                </Box>
                </motion.div>
                <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: transitionDuration }}
              >
                <Box>
                  <Button variant="outline" colorScheme="purple" onClick={() => handleClick('team_meeting')}>
                    Team meeting assistance
                  </Button>
                </Box>
          </motion.div>
          <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: transitionDuration }}
              >
                <Box>
                  <Button variant="outline" colorScheme="purple" onClick={() => handleClick('feedback_presentation')}>
                    Feedback on presentation
                  </Button>
                </Box>
                </motion.div>
                <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: transitionDuration }}
              >
                <Box>
                  <Button variant="outline" colorScheme="purple" onClick={() => handleClick('feedback_writing')}>
                    Feedback on writing
                  </Button>
                </Box>
                </motion.div>
                <Box
              flex="1" 
              display="flex"
              alignItems="flex-end"
            >
              <Text>Or ask AI anything:</Text>
            </Box>
            
              </VStack>
             
            ) : selectedOption === 'team_meeting' && !onTeam ? (
              <>
                <Box>
                  <IconButton
                    icon={<FaArrowLeftLong />}
                    aria-label="Back"
                    onClick={handleBackClick}
                    zIndex="1"
                    mr={2}
                    variant="ghost"
                  />
                </Box>
                <VStack spacing={4} align="flex-start" p={4} width={"100%"} height={"100%"}>
                <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: transitionDuration }}
  >
                <Text fontSize="xl" fontWeight="bold">
                  G-Ideas: How can I help?
                </Text>
                </motion.div>
                <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: transitionDuration }}
  >
                <Box>
                  <Button variant="outline" colorScheme="purple" onClick={() => handleClick('draft_meeting_agenda')}>
                    Draft a meeting agenda
                  </Button>
                </Box>
                </motion.div>
                <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: transitionDuration }}
  >
                <Box>
                  <Button variant="outline" colorScheme="purple" onClick={() => handleClick('summarize_meeting_notes')}>
                    Summarize meeting notes
                  </Button>
                </Box>
                </motion.div>
                <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: transitionDuration }}
  >
                <Box>
                  <Button variant="outline" colorScheme="purple" onClick={() => handleClick('tips_team_meeting')}>
                    Tips for how to run a team meeting
                  </Button>
                </Box>
                </motion.div>
                
                <Box
              flex="1" 
              display="flex"
              alignItems="flex-end"
            >
              <Text>Or ask AI anything:</Text>
            </Box>
            
              </VStack>
              
              </>
            ) : (
              
              <Flex direction="column" flex="1" overflowY="auto">
                <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: transitionDuration }}
  >
                <Box>
                <IconButton
                icon={<FaArrowLeftLong />}
                aria-label="Back"
                onClick={handleBackClick}
                zIndex="1"
                mr={2}
                variant="ghost"
              />
              </Box>
              </motion.div>
  <VStack
    spacing={4}
    align="stretch"
    flex="1"
    position={"relative"}
    overflowY="auto"
    w="100%"
    css={{
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none', 
      'scrollbar-width': 'none',  
    }}
    flexDirection={"column-reverse"}
  >
    {[...GImessages].reverse().map((msg, idx) => (
      <HStack
        key={idx}
        alignSelf={msg.role === 'user' ? 'flex-end' : 'flex-start'}
        maxWidth="80%"
        p={2}
        m={2}
        spacing={4} 
      >
        
        {msg.role !== 'user' && (
          <Avatar
            name="GI Avatar" 
            src= {giimage}
            size="sm"
          />
        )}

        <Box
          bg={msg.role === 'user' ? '#d5ebfd' : '#f0f4fc'}
          p={2}
          borderRadius="xl"
          color="black"
          wordBreak="break-word"
          overflowWrap="break-word"
          flex="1"
        >
          <div
            dangerouslySetInnerHTML={{ __html: msg.content }}
            onClick={(e) => {
              
              if (e.target.classList.contains('prompt-link')) {
                
                handlePromptClick(e);
              }
            }}
          />
        </Box>
      </HStack>
    ))}
  </VStack>
</Flex>

            )
) : (

          <Flex direction="column" flex="1" overflowY="auto">
  <VStack
    spacing={2}
    p={2}
    flex="1"
    overflowY="auto"
    bg="#f7f7f8"
    width="100%"
    fontFamily="initial"
    position="relative"
    flexDirection={"column-reverse"}
  >
    {filteredMessages.length === 0 && (
      <Text>No messages</Text>
    )}
    {isLoading ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress size="50px" isIndeterminate color="purple.900" />
      </Box>
    ) : (
      selectedProject._id === 'private' ? (
        [...filteredMessages].reverse().map((msg, idx) => (
          <Box
            key={idx}
            marginTop={2}
            bg={msg.role === 'user' ? '#d5ebfd' : '#f0f4fc'}
            p={2}
            m={2}
            borderRadius="xl"
            alignSelf={msg.role === 'user' ? 'flex-end' : 'flex-start'}
            maxWidth="80%"
            display="flex"
            flexDirection="column"
            color="black"
            wordBreak="break-word"
            overflowWrap="break-word"
            cursor="text"
            userSelect="text"
            
          >
            <Box flex="1">
              <Text css={{ whiteSpace: 'pre-wrap' }}>
                {parseMessageContent(msg.content, highlightText)}
              </Text>
            </Box>
            <Box textAlign="right">
              <IconButton
                size="xs"
                icon={<FiCopy />}
                aria-label="Copy message"
                variant="ghost"
                color="black"
                onClick={() => handleCopyMessage(msg.content)}
                
              />
            </Box>
          </Box>
        ))
      ) : (
        // For non-private project, first pair the messages, then render them
        [...filteredMessages].reverse().map((pair, idx) => {
          const sender = userMap[pair?.query?.userId];
          return (
            <Box
              key={idx}
              marginTop={2}
              bg={pair?.query?.userId === currentLoggedInSId ?  '#d5ebfd' : '#f0f4fc'}
              p={2}
              m={2}
              borderRadius="xl"
              alignSelf={pair?.query?.userId === currentLoggedInSId ? 'flex-end' : 'flex-start'}
              maxWidth="80%"
              display="flex"
              flexDirection="column"
              color="black"
              wordBreak="break-word"
              overflowWrap="break-word"
              cursor="text"
              userSelect="text"
              onMouseUp={() => {
                const selectedText = window.getSelection().toString();
                if (selectedText) {
                  setSelectedText(selectedText);
                }
              }}
            >
              <Box flex="1">
                <Text fontWeight="Bold" mb={1}>
                  {sender?.name}
                </Text>
                <Text css={{ whiteSpace: 'pre-wrap' }}>
                  {parseMessageContent(pair?.query?.content, highlightText)}
                </Text>
                <Text css={{ whiteSpace: 'pre-wrap', marginTop: '8px' }}>
                  <strong>ChatGPT:</strong> {parseMessageContent(pair?.response?.content, highlightText)}
                </Text>
              </Box>
              <Box textAlign="right">
                <IconButton
                  size="xs"
                  icon={<FiCopy />}
                  aria-label="Copy message"
                  variant="ghost"
                  onClick={() => handleCopyMessage(pair?.query?.content)}
                  _hover={{ color: "white" }}
                />
                <IconButton
                  size="xs"
                  icon={<FiTag />}
                  aria-label="Tag message"
                  variant="ghost"
                  onClick={() => handleTagClick(pair)}
                  color="black"
                />
              </Box>
            </Box>
          );
        })
      )
    )}
  </VStack>
</Flex>
)}

          <Flex
            p={2}
            borderTop="1px solid"
            borderColor="gray.200"
            borderBottomRadius="xl"
            alignItems="center"
            bg="#f7f7f8"
          >
            {selectedOption === 'summarize_meeting_notes' && isClicked && (
    <>
      <IconButton
        size="sm"
        icon={<FiPaperclip />} 
        aria-label="Attach file"
        onClick={() => document.getElementById('file-upload').click()} 
        mr={2} 
        bg={'purple.900'}
        color={'white'}
        _hover={{ color: "white" }}
      />
      <input
        type="file"
        id="file-upload"
        style={{ display: 'none' }}
        accept=".doc,.docx,.pdf,.txt"
        onChange={handleFileChange}
      />
    </>
  )}
            {isTextArea ? (
              <Textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    if(selectedOption && !completed)
                    handleAnswerSubmit(input);
                    else
                    handleSendMessage('');
                  }
                }}
                placeholder="Type your message..."
                resize="none"
                minHeight="60px"
                flex="1"
                onPaste={handlePaste}
              />
            ) : (
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if(selectedOption && !completed)
                    handleAnswerSubmit(input);
                    else
                    handleSendMessage('');
                  }
                }}
                placeholder="Type your message..."
                flex="1"
                height="40px"
                onPaste={handlePaste}
              />
            )}
            <IconButton
              size="sm"
              icon={<FiSend />}
              aria-label="Send message"
              onClick={() => {
                if (selectedOption && !completed) {
                  handleAnswerSubmit(input); 
                } else {
                  handleSendMessage(''); 
                }
              }}
              ml={2}
              bg={'purple.900'}
              color={'white'}
              isLoading={loading}
              _hover={{ color: "white" }}
            />
          </Flex>
        </Box>
      </Rnd>
    );
  
};

export default ChatGPTChat;
