import { Box, Container, Flex, IconButton, Input, InputGroup, InputRightElement, Text, useToast, useDisclosure, VStack, Avatar  } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Message } from './Message.tsx';
import { ChatInput } from './ChatInput.tsx';
import { UserPanel } from './UserPanel.tsx';
import { MessageCircle, X } from 'lucide-react';
import React from 'react';
import TDashboard from '../teacher-portal/Dashboard/Dashboard.tsx';
import { getTeachersByIds, getStudentsByIds, getProjectsByIds, AddMessage, AddMessagetoGroup } from "../../Services/Teacher/teacherService.tsx";
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../commons/LoadingSpinner.tsx';
import { pusherClient } from '../../pusherConfig.js';
import notificationSound from '../../Assets/notification.mp3';
import { VoiceRecorder } from './VoiceRecorder.tsx';
import EmojiPicker from 'emoji-picker-react';
import { Send, Smile } from 'lucide-react';
import avatar from '../../Assets/userImage.png';
import { UserDetailPage } from './userDetailPage.tsx';
import logounichat from "../../Assets/G-Ideas.png";

function Chat({ inputFromChat, clearPrefillMessage, signal = undefined }) {
  const [messages, setMessages] = useState<[]>();
  const [selectedUserId, setSelectedUserId] = useState(1);
  const { projectId, groupId } = useParams();
  const [project, setProject] = useState({});
  const [users, setUsers] = useState([]);
  const [userMap, setUserMap] = useState(JSON.parse(sessionStorage.getItem('userMap')) || {});
  const [messageInputValue, setMessageInputValue] = useState("");
  const [messageData, setMessageData] = useState([]);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentIsGroupChat, setCurrentIsGroupChat] = useState(true);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const MotionBox = motion(Box);
  const [isUserDetailOpen, setIsUserDetailOpen] = useState(false);
  const MotionFlex = motion(Flex);
  const userType = sessionStorage.getItem("userType");
  const [groupDetails, setGroupDetails] = useState({});

  let currentuser;
  if(userType === "teacher"){
    currentuser = sessionStorage.getItem("CurrentLoggedInTId")?.trim();
  }
  else if(userType === "student"){
    currentuser = sessionStorage.getItem("CurrentLoggedInSId")?.trim();
  }
  
  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const data = await getProjectsByIds([projectId]);
        if (data.length > 0) {
          setProject(data[0]);
          const GD = data[0].groups.filter(group => {
            return group._id === groupId;
        });
        setGroupDetails(GD[0]);
        setMessageData(GD[0].messages);
        } else {
          throw new Error("No Group data found.");
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Error fetching Group details.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [projectId, groupId, signal]);


  useEffect(() => {
    if (!groupDetails.studentIds || !groupDetails.teacherIds) return;

  const fetchUserData = async () => {
    try {
      const students = await getStudentsByIds(groupDetails.studentIds);
      const teachers = await getTeachersByIds(groupDetails.teacherIds);
      const allUsers = [...students, ...teachers];
  
      // Load previous user map from sessionStorage
      const previousUserMap = JSON.parse(sessionStorage.getItem('userMap')) || {};
      
      // Create a new user map from fetched data
      const newUserMap = {};
      allUsers.forEach(user => {
        
        newUserMap[user._id] = { 
          ...user, 
          unreadCount: previousUserMap[user._id]?.unreadCount || 0 
        };
      });
  
      
      const previousGroupChat = previousUserMap['groupChat'] || { unreadCount: 0 };
      const newGroupChat = {
        ...previousGroupChat,
        unreadCount: previousGroupChat.unreadCount, 
      };
  
      
      setUsers(allUsers);
      setUserMap({ ...newUserMap, groupChat: newGroupChat });
      
      
      sessionStorage.setItem('userMap', JSON.stringify({ ...newUserMap, groupChat: newGroupChat }));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  
  fetchUserData();
}, [project]);

useEffect(() => {
    if (inputFromChat) {
      if(!inputFromChat.query){
        const formattedMessage = `**Message from ChatGPT**\n\nMessage: ${inputFromChat || ''}`;
      setMessageInputValue(formattedMessage.trimEnd());
      }
      else{
      const formattedMessage = `**Message from ChatGPT**\n\nQuery: ${inputFromChat.query || ''}\n\nResponse: ${inputFromChat.response || ''}\n\nSent by: ${userMap[inputFromChat.userId]?.name || inputFromChat.userId}\n`;
      setMessageInputValue(formattedMessage.trimEnd()); 
      }
    }
  }, [inputFromChat, userMap]);

  useEffect(() => {
    if (!groupDetails._id) return;
  
    const projectChannel = pusherClient.subscribe(groupDetails._id);
    const peerChannel = pusherClient.subscribe(`peerchat-${groupDetails._id}`);
  
    const handleNewMessages = (data) => {
      let newM = [data.messages];
      let allMessages;
      if (!data.isGroupChat) {
        allMessages = [...(groupDetails.individualmessages || []), ...newM];
        if (!currentIsGroupChat && selectedUser) {
          const filteredMessages = allMessages.filter(
            ele => (ele.sender === currentuser && ele.receiver === selectedUser._id) ||
                   (ele.sender === selectedUser._id && ele.receiver === currentuser)
          );
          setMessageData(filteredMessages);
        }
       
        setProject(prevProject => {
          const updatedGroups = prevProject.groups.map(grp => {
            if (grp._id === groupDetails._id) {
              return {
                ...grp,
                individualmessages: allMessages
              };
            }
            return grp;
          });
    
          const updatedProject = {
            ...prevProject,
            groups: updatedGroups
          };
          setProject(updatedProject);
          const updatedGroupDetails = updatedProject.groups.find(grp => grp._id === groupDetails._id);
          setGroupDetails(updatedGroupDetails);
    
          return updatedProject;
        });
  
      } else {
        allMessages = [...(groupDetails.messages || []), ...newM];
        if(currentIsGroupChat){
          setMessageData(allMessages);
        }
        
        setProject(prevProject => {
          const updatedGroups = prevProject.groups.map(grp => {
            if (grp._id === groupDetails._id) {
              return {
                ...grp,
                messages: allMessages
              };
            }
            return grp;
          });
    
          const updatedProject = {
            ...prevProject,
            groups: updatedGroups
          };
          setProject(updatedProject);
          const updatedGroupDetails = updatedProject.groups.find(grp => grp._id === groupDetails._id);
          setGroupDetails(updatedGroupDetails);
    
          return updatedProject;
        });
      }
  
      const lastMessage = data.messages;
      if (lastMessage && lastMessage.sender !== currentuser) {
        const audio = new Audio(notificationSound);
        if (document.interacted) {
          const audio = new Audio(notificationSound);
          audio.play();
        } else {
          document.addEventListener('click', () => {
            const audio = new Audio(notificationSound);
            audio.play();
            document.interacted = true;
          }, { once: true });
        }
  
        const updatedUserMap = { ...userMap };
  
        if (!lastMessage.isPeerMessage) {
          updatedUserMap["groupChat"] = {
            ...updatedUserMap["groupChat"],
            unreadCount: (updatedUserMap["groupChat"]?.unreadCount || 0) + 1,
          };
        } else {
          const senderId = lastMessage.sender;
          if (updatedUserMap[senderId]) {
            updatedUserMap[senderId] = {
              ...updatedUserMap[senderId],
              unreadCount: (updatedUserMap[senderId].unreadCount || 0) + 1,
            };
          }
        }
  
        setUserMap(updatedUserMap);
        console.log(updatedUserMap)
        sessionStorage.setItem('userMap', JSON.stringify(updatedUserMap)); // Update sessionStorage
  
        toast({
          title: "New Message",
          description: `You have a new message from ${userMap[lastMessage.sender]?.name || 'unknown'}.`,
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      }
    };
  
    projectChannel.bind('messages:new', handleNewMessages);
    peerChannel.bind('individualmessages:new', handleNewMessages);
  
    return () => {
      projectChannel.unbind('messages:new', handleNewMessages);
      pusherClient.unsubscribe(project._id);
      peerChannel.unbind('individualmessages:new', handleNewMessages);
      pusherClient.unsubscribe(`peerchat-${project._id}`);
    };
  }, [groupDetails._id, currentuser, userMap, toast]);

  const handleUserClick = (user) => {
    const updatedUserMap = { ...userMap };
    if (user) {
      updatedUserMap[user._id] = {
        ...updatedUserMap[user._id],
        unreadCount: 0,
      };
    } else {
      if (!updatedUserMap["groupChat"]) {
        updatedUserMap["groupChat"] = { unreadCount: 0 };
      } else {
        updatedUserMap["groupChat"].unreadCount = 0;
      }
    }
    setUserMap(updatedUserMap);
    sessionStorage.setItem('userMap', JSON.stringify(updatedUserMap)); // Update sessionStorage

    setSelectedUser(user);
    setCurrentIsGroupChat(user === null);

    if (user) {
      const filteredMessages = groupDetails?.individualmessages?.filter(
        (ele) =>
          (ele.sender === currentuser && ele.receiver === user._id) ||
          (ele.sender === user._id && ele.receiver === currentuser)
      );
      setMessageData(filteredMessages);
    } else {
      setMessageData(groupDetails?.messages);
    }
  };

  const onSend = async () => {
    const newMessage = {
      message: messageInputValue,
      sender: currentuser,
    };
    setMessageInputValue("");

    try {
      if(messageInputValue.length>0){
      if (selectedUser) {
        await AddMessagetoGroup({ id: groupDetails._id, senderId: currentuser, receiverId: selectedUser._id }, newMessage);
      } else {
        await AddMessagetoGroup({ id: groupDetails._id }, newMessage);
      }
      clearPrefillMessage();
    }
    } catch (error) {
      toast({
        title: "Error",
        description: "Error sending message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onSendVoice = (audioBlob: Blob) => {
    const audioUrl = URL.createObjectURL(audioBlob);
    const newMessage: ChatMessage = {
      id: messages.length + 1,
      content: "",
      sender: "You",
      timestamp: new Date().toLocaleTimeString(),
      isOwn: true,
      audioUrl,
    };
    setMessages([...messages, newMessage]);
  };

  const handleSend = () => {
    if (messageInputValue.trim()) {
      onSend();
      setMessageInputValue('');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const onEmojiClick = (emojiData: any) => {
    setMessageInputValue(prev => prev + emojiData.emoji);
    onClose();
  };
  
  const handleDetailUser = () => {
    setIsUserDetailOpen(prev => !prev);
  }
   


  return (
    <Box w={"105%"} mx={-9}  h="86vh" my={-3} p={4}>
      <Flex
        h="full"
        bg="white"
        borderRadius="xl"
        boxShadow="xl"
        overflow="hidden"
      >
        {groupDetails?.nativeChat === false ? (
          <iframe
            src={`https://e.widgetbot.io/channels/${groupDetails.discordServerId}`}
            width="100%"
            height="100%"
          />
        ) : (
          <>
        <UserPanel 
          users={users}
          onUserClick={handleUserClick}
          name={groupDetails?.groupName}
          selectedUser={selectedUser}
          userM={userMap}
        />
        <AnimatePresence>
        <Flex
            direction="column"
            flex={1}
            borderRight="1px"
            borderColor="gray.200"
        >
            <AnimatePresence mode="wait">
                <Box p={4} bg="#f0f2f5">
                    <Flex align="center" gap={2} justify="space-between">
                        {selectedUser ? (
                        <Flex align="center" gap={4} cursor="pointer" onClick={() => handleDetailUser(selectedUser)}>
                            <Avatar
                            src={selectedUser.photoUrl || selectedUser.photoURL}
                            w="40px"
                            h="40px"
                            borderRadius="full"
                            />
                            <Text fontSize="xl" fontWeight="bold">{selectedUser.name}</Text>
                        </Flex>
                        ) : (
                          <Flex align="center" gap={4} cursor="pointer">
                          <Avatar
                          src={logounichat}
                          w="40px"
                          h="40px"
                          borderRadius="full"
                          />
                          <Text fontSize="xl" fontWeight="bold">{groupDetails?.groupName}</Text>
                      </Flex>
                        )}
                    </Flex>
                </Box>
            </AnimatePresence>
             <Box
                flex={1}
                p={4}
                overflowY="auto"
                css={{
                    '&::-webkit-scrollbar': { display: 'none' },
                    '-ms-overflow-style': 'none', // IE and Edge
                    'scrollbar-width': 'none', // Firefox
                }}
            >
               <AnimatePresence>
                  <VStack spacing={4} align="stretch" h="100%">
                    {messageData.length === 0 ? (
                      <Box textAlign="center" p={4} color="gray.500">
                        <Text>No messages</Text>
                      </Box>
                    ) : (
                      <VStack
                        spacing={4}
                        flex="1"
                        flexDirection="column-reverse"
                        overflowY="auto"
                        w="100%"
                        css={{
                          '&::-webkit-scrollbar': { display: 'none' },
                          '-ms-overflow-style': 'none',
                          'scrollbar-width': 'none',
                        }}
                      >
                        {[...messageData].reverse().map((message) => (
                          <Message key={message._id} message={message} currentuser={currentuser} selectedUser={selectedUser} userM={userMap} />
                        ))}
                      </VStack>
                    )}
                  </VStack>
                </AnimatePresence>

            </Box>
            <Box p={4} borderTop="1px" borderColor="gray.100" bg="#f0f2f5">
            <Box position="relative">
                <AnimatePresence>
                {isOpen && (
                    <MotionBox
                    position="absolute"
                    bottom="100%"
                    right={0}
                    mb={2}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ type: 'spring', stiffness: 300, damping: 25 }}
                    >
                    <EmojiPicker onEmojiClick={onEmojiClick} />
                    </MotionBox>
                )}
                </AnimatePresence>
                <InputGroup size="lg">
                <Input
                    pr="8rem"
                    placeholder="Type a message..."
                    value={messageInputValue}
                    onChange={(e) => setMessageInputValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                    bg="white"
                    borderRadius="full"
                    boxShadow="sm"
                    _focus={{
                    borderColor: 'blue.500',
                    boxShadow: '0 0 0 2px var(--chakra-colors-blue-200)',
                    }}
                    _hover={{
                    borderColor: 'blue.300',
                    }}
                />
                <InputRightElement width="8rem">
                    {/* <VoiceRecorder onSendVoice={onSendVoice} /> */}
                    <IconButton
                    h="1.75rem"
                    size="sm"
                    icon={<Smile size={18} />}
                    aria-label="Choose emoji"
                    variant="ghost"
                    onClick={onToggle}
                    mr={1}
                    _hover={{
                        color: 'blue.500',
                        bg: 'blue.50',
                    }}
                    />
                    <IconButton
                    h="1.75rem"
                    size="sm"
                    icon={<Send size={18} />}
                    aria-label="Send message"
                    colorScheme="blue"
                    isRound
                    onClick={handleSend}
                    _hover={{
                        transform: 'translateX(2px)',
                    }}
                    transition="all 0.2s"
                    />
                </InputRightElement>
                </InputGroup>
            </Box>
            </Box>
        </Flex>
    </AnimatePresence>

    <AnimatePresence>
    {isUserDetailOpen && (
        <UserDetailPage 
        selectedUser={selectedUser} 
        closeUserDetail={() => setIsUserDetailOpen(false)} 
        />
  )}
    </AnimatePresence>
    </>
        )}
    </Flex>
</Box>
  );
}

export default Chat;