import {
    Box,
    SimpleGrid,
    Text,
    Badge,
    VStack,
    HStack,
    Icon,
    Input,
    Spinner,
  } from '@chakra-ui/react';
  import { Users, GraduationCap } from 'lucide-react';
  import React, { useEffect, useState } from 'react';
  import ADashboard from '../AdminDashboard/ADashboard.tsx';
  import { getClasses } from '../../../Services/Admin/AdminService.tsx';
import { LoadingSpinner } from '../../../commons/LoadingSpinner.tsx';
  
  function ClassCard({ classInfo }: { classInfo: any }) {
    const schedule = classInfo?.schedule
      .map(item => item.day.charAt(0).toUpperCase() + item.day.slice(1, 3)) 
      .join(' ');
  
    const teachers = classInfo?.teachers
      .map(item => item?.name)
      .filter(name => name) 
      .join(', '); 
  
    const time = classInfo?.schedule[0]?.startTime
      ? classInfo?.schedule[0]?.startTime + "-" + classInfo?.schedule[0]?.endTime
      : '';
  
    return (
      <Box
        bg="white"
        p={6}
        rounded="lg"
        shadow="sm"
        borderWidth="1px"
        _hover={{ shadow: 'md' }}
        transition="all 0.2s"
      >
        <VStack align="stretch" spacing={4}>
          <Box>
            <Text fontSize="lg" fontWeight="bold" color="purple.900">
              {classInfo.classname}
            </Text>
            <Text color="gray.600">{teachers}</Text>
            <Text fontSize={15} color="gray.500">{classInfo.gradelevel}</Text>
          </Box>
  
          <HStack spacing={4}>
            <HStack>
              <Icon as={Users} color="purple.900" />
              <Text>{classInfo.studentCount} Students</Text>
            </HStack>
            <Badge colorScheme="purple" px={2} py={1}>
              {classInfo?.status || "Active"}
            </Badge>
          </HStack>
  
          <Box>
            <Text fontSize="sm" color="gray.600">Schedule:</Text>
            <Text fontWeight="medium">{schedule || "N/A"}</Text>
            <Text fontSize="sm">{time}</Text>
          </Box>
        </VStack>
      </Box>
    );
  }
  
  function Classes() {
    const [classes, setClasses] = useState([]);
    const [filteredClasses, setFilteredClasses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const isAdmin = sessionStorage.getItem('isAdmin');
  
    useEffect(() => {
      const fetchClasses = async () => {
        try {
          setLoading(true);
          const classes = await getClasses(isAdmin);
          setClasses(classes);
          setFilteredClasses(classes);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching classes:', error);
          setLoading(false);
        }
      };
      fetchClasses();
    }, [isAdmin]);
  
    useEffect(() => {
      const results = classes.filter(classInfo => 
        (classInfo.classname && classInfo.classname.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (classInfo.gradelevel && classInfo.gradelevel.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (classInfo.teachers && classInfo.teachers.some(teacher => 
          teacher.name && teacher.name.toLowerCase().includes(searchTerm.toLowerCase())
        ))
      );
      setFilteredClasses(results);
    }, [searchTerm, classes]);
  
    return (
      <ADashboard>
        <Box mt={24} ml={6} mr={6}>
          <Text fontSize="2xl" fontWeight="bold" mb={8}>All Classes</Text>
  
          <Input
            maxW="400px"
            placeholder="Search class..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mb={4}
          />
  
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="500px">
              <LoadingSpinner />
            </Box>
          ) : (
            <Box height="550px" overflowY="auto"
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none',
            }}
            >
              {filteredClasses.length === 0 ? (
                <Text>No classes found</Text>
              ) : (
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                  {filteredClasses.map((classInfo) => (
                    <ClassCard key={classInfo.id} classInfo={classInfo} />
                  ))}
                </SimpleGrid>
              )}
            </Box>
          )}
        </Box>
      </ADashboard>
    );
  }
  
  export default Classes;
  